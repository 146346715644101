export class EmbedValidatorError extends Error {
	constructor (...args) {
		super(...args)

		// this.name = this.constructor.name; でも問題ないが、
		// enumerable を false にしたほうがビルトインエラーに近づく、
		Object.defineProperty(this, 'name', {
			configurable: true,
			enumerable: false,
			value: this.constructor.name,
			writable: true
		})

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, EmbedValidatorError)
		}
	}
}

/**
 * バリデーションを行う
 * @param {String} code ユーザから入力された埋め込みコード
 * @param {Object} rules 許可する要素名（小文字）をキー、ルールを値とした配列
 * @return {String} 正規化された埋め込みコード
 * @throws {EmbedValidatorError}
 * @see checkElements
 */
function validate (code, rules) {
	const sandbox = document.createElement('div')
	sandbox.innerHTML = code
	checkElements(sandbox.firstChild, rules)
	return sandbox.innerHTML
}

/**
 * バリデーションを行う
 * @param {Node} node サンドボックスまたはその子孫要素の firstChild
 * @param {Object} rules 許可する要素名（小文字）をキー、ルールを値とした配列
 * @return {void}
 * @throws {EmbedValidatorError}
 */
function checkElements (node, rules) {
	while (node) {
		const nextNode = node.nextSibling
		switch (node.nodeType) {
		case 1: { // Element
			const nodeName = node.nodeName.toLowerCase()
			if (!rules[nodeName]) throw new EmbedValidatorError(`Element "${nodeName}" is not allowed.`)
			const rule = rules[nodeName]
			checkAttrs(node, rule.attrs || [], rule.fns || [])
			break
		}
		case 3: { // Text
			break
		}
		default: { // Comment and others
			node.parentNode.removeChild(node)
			break
		}
		}
		if (node.firstChild) {
			checkElements(node.firstChild, rules)
		}
		node = nextNode
	}
}

/**
 * バリデーションを行う
 * @param {Node} node 調べる要素
 * @param {String[]} allowAttrs 許可する属性名のリスト
 * @param {Function[]} fns カスタムエラーチェック関数
 * @return {void}
 * @throws {EmbedValidatorError}
 */
function checkAttrs (node, allowAttrs, fns) {
	for (let attrs = node.attributes, i = attrs.length - 1; i >= 0; i--) {
		const attrName = attrs[i].name.toLowerCase()
		if (attrName.indexOf('data-') === 0) continue
		if (!allowAttrs.includes(attrName)) throw new EmbedValidatorError(`Attribute "${attrName}" of element "${node.nodeName}" is not allowed.`)
	}

	fns.forEach(fn => {
		if (!fn(node)) throw new EmbedValidatorError('Invalid element.')
	})
}

export const EmbedValidator = {
	validate
}
