let ready = (() => {
	const rs = document.readyState
	return (rs === 'complete' || rs === 'interactive')
})()

/// @param listener: function
function addDOMReadyListener (listener) {
	if (ready) {
		setTimeout(listener)
	} else {
		document.addEventListener('DOMContentLoaded', listener, { once: true })
	}
}

document.addEventListener('DOMContentLoaded', function () {
	ready = true
}, { once: true })

export default addDOMReadyListener
