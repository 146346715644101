import Sortable from 'sortablejs'
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import View from '../View'
import DOM from '../dom/DOM'
import PictureItem from './PictureItem'

const EE = DOM.create

const MaxPictures = 3

function PictureC () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		pictures: []
	}
	me.dom = null
	me.nest = null // 子要素 (PictureItem) を入れる DOM 要素

	me.initView.apply(me, arguments)
}

View.registerClass('PictureC', PictureC, {

	createDOM () {
		const { id, data } = this

		this.nest = EE('div.ccnti')
		this.dom = EE('div.bs-ed-view.bs-ed-view-PictureC', { id }, [
			EE('div.ccnt', {}, [
				this.nest
			]),
			EE('div.cadd', {}, [
				EE('button.caddb.bs-ed-btn.bs-ed-btn-default.bs-ed-act', {
					title: '画像を追加',
					'data-act': 'addItem'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-plus')
				])
			])
		])

		// 必ず一つ以上の PictureItem が存在する
		if (data.pictures.length < 1) {
			data.pictures.push(new PictureItem(this))
		}

		// 子要素 (PictureItem) 数を制限
		if (data.pictures.length >= MaxPictures) {
			const removes = data.pictures.splice(MaxPictures)
			removes.forEach(picture => {
				View.unregister(picture)
			})
			this.dom.classList.add('full')
		}

		// 子要素 (PictureItem) を追加
		data.pictures.forEach(picture => {
			this.nest.appendChild(picture.getDOM())
		})

		// 子要素をソート可能にする
		this.sortable = new Sortable(this.nest, {
			// direction: "horizontal",
			handle: '.bs-ed-editor-sort-handle',
			animation: 200,
			onUpdate () {
				this.sortHandler()
			}
		})

		return this.dom
	},

	appendView (view) {
		this.ppView.appendChild(view.getDOM())
	},

	sortHandler () {
		// DOM が並び変わっているので、内部データをそれに合わせる
		var me = this; var pictures = [];

		[].forEach.call(me.nest.querySelectorAll('.bs-ed-view'), function (viewE) {
			var view = View.get(viewE.id)
			pictures.push(view)
		})
		me.data.pictures = pictures
	},

	/// 「画像を追加」ボタンが押された
	addItem () {
		var me = this; var picture

		if (me.data.pictures.length >= MaxPictures) {
			return
		}

		picture = new PictureItem(me)
		me.data.pictures.push(picture)
		me.nest.append(picture.getDOM())

		if (me.data.pictures.length >= MaxPictures) {
			me.dom.classList.add('full')
		}

		me.parentView.notifyChange()
	},

	/// 子要素を削除する。子要素から呼び出す
	removePicture (picture) {
		var me = this; var i = 0; var p; var ri = null

		// 必ず一つは残す
		if (me.data.pictures.length <= 1) {
			return
		}

		// 子要素のインデックスを特定
		while ((p = me.data.pictures[i])) {
			if (p.id === picture.id) {
				picture.getDOM().classList.add('out')
				ri = i
				break
			}
			i++
		}

		// 子要素でなければ何もしない
		if (ri === null) {
			return
		}

		// 子要素を削除する
		me.data.pictures.splice(ri, 1)
		DOM.remove(picture.getDOM())
		View.unregister(picture)

		me.parentView.notifyChange()

		if (me.data.pictures.length < MaxPictures) {
			me.dom.classList.remove('full')
		}
	},

	/// 子要素を削除する (確認あり)
	removePictureConfirm (picture) {
		var me = this; var dialog

		// 必ず一つは残す
		if (me.data.pictures.length <= 1) {
			return
		}

		dialog = new Dialog('画像の削除', 'この画像を削除してもよろしいですか？', DialogButton.OKCancel)
		dialog.on('hide', (retval) => {
			if (retval === 'OK') {
				me.removePicture(picture)
			}
		})
		dialog.show()
	}

})

export default PictureC
