/* global zzl */
import Editor from './Editor'
import View from './View'
import DOM from './dom/DOM'
import Evt from './dom/Event'
import Sortable from 'sortablejs'

var resizeTimer

/*
	const bridge = document.getElementById('editor-input')
	const editor = new BS.Editor(bridge, { ...options })
*/

function EditorManager (bridge, options) {
	var me = this

	me.bridge = bridge
	me.editor = null
	me.dom = null
	me.init(options)
}

EditorManager.getPlugin = function (name) {
	switch (name) {
	case 'sortablejs': return Sortable
	default: return null
	}
}

EditorManager.prototype = {

	init (options) {
		var me = this

		if (!window.JSON) {
			me.showErrorMessage('お使いの環境では編集できません')
			return
		}

		if (!window.zzl || !zzl.env || !zzl.uploadImagePrefix) {
			me.showErrorMessage('環境変数が設定されていません')
			return
		}

		const editorData = (json => {
			try {
				return JSON.parse(json)
			} catch (e) {
				return { T: 'Editor', parts: [] }
			}
		})(me.bridge.value)

		const containerE = DOM.create('div.bs-ed-wrap')
		me.dom = containerE
		DOM.insertAfter(containerE, me.bridge)

		const editor = new Editor(this, editorData, options || {})
		me.editor = editor
		containerE.appendChild(editor.getDOM())

		const eventTypes = ['change', 'input', 'dragenter', 'dragleave', 'dragover', 'drop', 'dragend']
		eventTypes.forEach(type => Evt.on(containerE, type, me))

		Evt.on(window, 'resize', () => me.resizeHandler.apply(me, arguments))
	},

	handleEvent (event) {
		var me = this; var targetE; var viewE; var view; var key; var dropTarget

		targetE = event.target
		viewE = DOM.closest('.bs-ed-view', targetE)
		if (!viewE) {
			return
		}
		view = View.get(viewE.id)

		switch (event.type) {
		case 'change':
		case 'input':
			if (view == null) {
				return
			}
			key = targetE.getAttribute('bs-ed-var')
			if (key == null) {
				return
			}
			view.onUpdate(key, targetE.value, event)
			this.notifyChange()
			break

		case 'dragenter':
			event.preventDefault()
			dropTarget = DOM.closest('.bs-ed-drop-target', targetE)
			if (dropTarget) {
				dropTarget.classList.add('bs-ed-drag-active')
			} else {
				me.disactiveDropTarget()
			}
			break

		case 'dragover':
			event.preventDefault()
			break

		case 'drop':
			me.disactiveDropTarget()
			view.onDrop(event)
			break

		case 'dragend':
			me.disactiveDropTarget()
			break
		}
	},

	disactiveDropTarget () {
		[].forEach.call(DOM.qs('.bs-ed-drop-target.bs-ed-drag-active'), el => el.classList.remove('bs-ed-drag-active'))
	},

	notifyChange () {
		this.bridge.value = JSON.stringify(this.editor.getData())
	},

	showErrorMessage (message) {
		var messageE = DOM.create('div.bs-ed-error-message', {}, [DOM.text(message)])
		DOM.insertAfter(messageE, this.bridge)
	},

	resizeHandler () {
		if (resizeTimer) {
			clearTimeout(resizeTimer)
		}
		setTimeout(View.updateAll, 150)
	}
}

export default EditorManager
