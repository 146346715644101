/// @param selection: Selection
function SelectionBookmark (selection) {
	var me = this

	me.selection = selection

	me.startContainer = null
	me.startOffset = null
	me.endContainer = null
	me.endOffset = null
}

SelectionBookmark.prototype = {

	/// @return Range?
	get () {
		var me = this; var range

		if (!me.startContainer) {
			return null
		}

		try {
			range = me.selection.createRange()
			range.setStart(me.startContainer, me.startOffset)
			range.setEnd(me.endContainer, me.endOffset)
			return range
		} catch (e) {
			return null
		}
	},

	/// @param Range
	set (range) {
		var me = this; var normalizedRange

		normalizedRange = me.selection.normalizeRange(range)
		if (normalizedRange) {
			me.startContainer = normalizedRange.startContainer
			me.startOffset = normalizedRange.startOffset
			me.endContainer = normalizedRange.endContainer
			me.endOffset = normalizedRange.endOffset
		} else {
			me.clear()
		}

		// console.log("range was set: ", {
		//   start: me.startContainer,
		//   startOffset: me.startOffset,
		//   end: me.endContainer,
		//   endOffset: me.endOffset
		// });
	},

	clear () {
		var me = this
		me.startContainer = null
		me.startOffset = null
		me.endContainer = null
		me.endOffset = null
	}

}

export default SelectionBookmark
