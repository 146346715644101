import util from '../util'
import DOM from './DOM'
import SelectionBookmark from './SelectionBookmark'
import SelectionFormat from './SelectionFormat'

// DOM を変更する際にカーソル位置を保存するための要素
const caretID = '__caret'

// ゼロ幅スペース
const ZWSP = '\u200B'

class Selection {
	/// @param window: Window
	/// @param E: Element
	constructor (window, E) {
		var me = this

		me.window = window
		me.document = me.window.document
		me.body = me.document.body
		me.root = null
		me.E = E
		me.bookmark = new SelectionBookmark(me)
	}

	setRoot (root) {
		this.root = root
	}

	/// @return boolean
	isCollapsed () {
		const range = this.getRange()
		return !range || range.collapsed
	}

	/// @return string?
	getText () {
		const selection = this.getSelection()
		if (!selection) {
			return null
		}

		if (this.isCollapsed()) {
			return ''
		}

		return selection.toString()
	}

	/// @param HTML: string
	setHTML (HTML) {
		var me = this; var range; var caretE

		range = me.getRange()
		if (!range) {
			return
		}

		// Firefox, Chrome: 選択範囲が <br> の内部にあるとうまく insert できない
		if (range.startContainer && range.startContainer.nodeName === 'BR') {
			range.setStartBefore(range.startContainer)
		}
		if (range.endContainer && range.endContainer.nodeName === 'BR') {
			range.setEndBefore(range.endContainer)
		}

		// カーソル位置を保存するために目印をつける
		HTML += '<span id="' + caretID + '">_</span>'

		// HTML をセット
		range.deleteContents()

		if (me.body.childNodes.length === 0) {
			me.body.innerHTML = HTML
		} else {
			range.insertNode(range.createContextualFragment(HTML))
		}

		// カーソル位置を復元
		caretE = DOM.q('#' + caretID, me.body)

		range = me.createRange()
		range.setStartBefore(caretE)
		range.setEndBefore(caretE)
		me.setRange(range)

		// 目印を削除
		DOM.remove(caretE)
	}

	/// @return Element?
	getNode () {
		var me = this; var range; var node

		range = me.getRange()
		if (!range) {
			return null
		}

		node = range.commonAncestorContainer
		if (DOM.isTextNode(node)) {
			return node.parentNode // テキストノードの場合は親要素を返す
		}
		return node
	}

	/// @param node: Element
	setNode (node) {
		var me = this

		me.setHTML(DOM.getOuterHTML(node))
	}

	/// @return Range
	createRange () {
		return this.document.createRange()
	}

	/// @return Range
	getRange () {
		var me = this; var range; var selection

		range = me.bookmark.get()
		if (range) {
			return range
		}

		selection = me.getSelection()
		if (!selection || selection.rangeCount === 0) {
			return null
		}

		try {
			range = selection.getRangeAt(0)
		} catch (e) {
			return null
		}
		if (!range) {
			return null
		}

		return me.normalizeRange(range)
	}

	/// @return Selection
	getSelection () {
		var win = this.window

		return win.getSelection ? win.getSelection() : win.document.selection
	}

	/// @param Range
	setRange (range) {
		var me = this; var selection

		selection = me.getSelection()
		if (!selection) {
			return
		}

		try {
			selection.removeAllRanges()
			selection.addRange(range)
			me.bookmark.set(range)
		} catch (e) {}
	}

	/// Range を正規化する（内容参照）
	/// @param range: Range
	/// @return Range?
	normalizeRange (range) {
		let start = range.startContainer
		let soff = range.startOffset
		let end = range.endContainer
		let eoff = range.endOffset

		const { root } = this
		if (!root || (!DOM.closest(root, start) && !DOM.closest(root, end))) {
			return null
		}

		// 終点は最も深いテキストノードを指す
		if (eoff === 0) {
			const closestBlock = DOM.closest(DOM.blockElements.join(','), end)
			let prevOfEnd = end
			while (prevOfEnd && prevOfEnd !== root && prevOfEnd !== closestBlock) {
				prevOfEnd = DOM.traverseReverse(prevOfEnd)
				if (DOM.isTextNode(prevOfEnd)) {
					break
				}
			}
			if (prevOfEnd && prevOfEnd !== root && prevOfEnd !== closestBlock) {
				end = prevOfEnd
				eoff = prevOfEnd.length
			}

			// もし end === root となった場合は最も先頭にあるテキストノードを指す
			if (end === root) {
				let firstP = DOM.q(DOM.paragraphElements, root)
				if (!firstP) {
					firstP = DOM.create('p')
					root.appendChild(firstP)
				}

				let firstText = firstP.firstChild
				if (!firstText) {
					firstText = DOM.text(ZWSP)
					firstP.appendChild(firstText)
				}

				end = firstText
				eoff = 0
			}
		}

		// end が root の末尾を指している場合は子要素の中に入れる
		if (end === root && eoff === root.childNodes.length) {
			end = root
			while (end.lastChild) {
				end = end.lastChild
			}
			eoff = DOM.isTextNode(end) ? end.length : 0
		}

		// start が root に含まれない場合は root の最初を指す (Edge)
		if (start === root && soff === 0) {
			start = root
			while (start.firstChild) {
				start = start.firstChild
			}
			soff = 0
		}

		// 範囲が 0 の場合は前のノードの終端を正規とする
		if (range.collapsed) {
			start = end
			soff = eoff
		}

		try {
			const newRange = this.createRange()
			newRange.setStart(start, soff)
			newRange.setEnd(end, eoff)
			return newRange
		} catch (e) {
			return null
		}
	}

	/// selection が変更されたときに selection を記録する
	updateBookmark () {
		const selection = this.getSelection()
		if (!selection || selection.rangeCount === 0) {
			this.bookmark.clear()
			return
		}

		try {
			const range = selection.getRangeAt(0)
			if (range) {
				const nRange = this.normalizeRange(range)
				this.bookmark.set(nRange)
				selection.setRange(nRange)
			}
		} catch (e) {}
	}
}

// prototype を SelectionFormat で拡張する
util.addProps(Selection.prototype, SelectionFormat)

export default Selection
