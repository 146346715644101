/* global zzl */
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import View from '../View'
import DOM from '../dom/DOM'
import DOMUtil from '../dom/DOMUtil'
import ImageUploader from '../uploads/ImageUploader'

const EE = DOM.create

const MaxSize = 1000
const errorDialog = new Dialog('写真を追加できません', '', DialogButton.OK)

function PictureItem (obj, group) {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		uid: null,
		type: null,
		name: null,
		size: null,
		width: null,
		height: null,
		caption: '',
		alt: '',
		link_url: '',
		link_target: null
	}
	me.dom = null
	me.textarea = null
	me.uploader = null
	me.initView.apply(me, arguments)
}

View.registerClass('PictureItem', PictureItem, {

	createDOM () {
		const { id, data } = this

		this.fileInput = EE('input', {
			type: 'file',
			accept: 'image/*',
			title: '配置する画像を選択します',
			tabIndex: '-1',
			'bs-ed-var': 'file'
		})
		this.textarea = EE('textarea.bs-ed-input-tr.ccap', {
			placeholder: 'キャプション...',
			'bs-ed-var': 'caption'
		}, [data.caption])

		const dom = EE('div.bs-ed-view.bs-ed-view-PictureItem', { id }, [
			EE('div.cimg', {}, [
				EE('label.cthumb.bs-ed-drop-target', {}, [
					this.fileInput,
					EE('img.img', {
						alt: '',
						src: data.uid ? (zzl.uploadImagePrefix + data.uid) : ''
					}),
					EE('span.placeholder', {}, ['画像を選択'])
				]),
				EE('button.cimgrm.bs-ed-act', {
					tabIndex: '-1',
					'data-act': 'removeConfirm'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-remove', { title: 'この画像を削除する' })
				])
			]),
			EE('div.ctool', {}, [
				this.textarea,
				EE('button.settings.bs-ed-act', {
					title: '画像設定',
					tabIndex: '-1',
					'data-act': 'openSettings'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-cog')
				])
			])
		])
		if (data.uid) {
			dom.classList.add('has-img')
		}
		if (data.link_url) {
			dom.classList.add('has-link')
		}

		setTimeout(() => DOMUtil.adjustHeight(this.textarea))

		return dom
	},

	getUploads () {
		return this.data.uid ? [this.data.uid] : []
	},

	// DOM 部品からの変更通知
	onUpdate (key, value, event) {
		var me = this

		PictureItem.__super__.onUpdate.apply(me, arguments)
		if (key === 'file') {
			if (event && event.target && event.target.files && event.target.files[0]) {
				me.addUpload(event.target.files[0])
			}
			me.resetFileInput()
		}
		if (key === 'caption' && me.textarea) {
			DOMUtil.adjustHeight(me.textarea)
		}
	},

	// DOM からのドロップ通知
	onDrop (event) {
		var me = this; var dataTransfer
		dataTransfer = event.originalEvent ? event.originalEvent.dataTransfer : event.dataTransfer
		if (!dataTransfer) {
			return
		}
		event.preventDefault()
		if (dataTransfer.files && dataTransfer.files[0]) {
			me.addUpload(dataTransfer.files[0])
		}
	},

	// <input type="image"> の値を消去して次の入力に備える
	resetFileInput () {
		var fileInput = this.dom.querySelector("input[type='file']")
		fileInput.value = ''
	},

	addUpload (file) {
		var me = this
		me.dom.classList.remove('error')

		me.uploader = new ImageUploader({
			file,
			complete: res => me.uploadComplete(res),
			error: err => me.uploadError(err),
			maxSize: MaxSize
		})
	},

	destroy () {
		this.uploader = null
	},

	uploadComplete (data) {
		var me = this; var key
		for (key in me.data) {
			if (Object.prototype.hasOwnProperty.call(me.data, key) && data[key] != null) {
				me.data[key] = data[key]
			}
		}
		if (me.data.uid) {
			me.dom.classList.add('has-img')
			me.dom.querySelector('.img').src = zzl.uploadImagePrefix + me.data.uid
		}
		me.parentView.notifyChange()
	},

	uploadError (message) {
		var me = this
		me.dom.classList.add('error')

		if (!errorDialog.isShown) {
			errorDialog.setSubtitle(localizeErrorMessage(message))
			errorDialog.show()
		}
	},

	remove () {
		var me = this
		if (typeof me.parentView.removePicture === 'function') {
			me.parentView.removePicture(me)
		}
	},

	removeConfirm () {
		var me = this
		if (typeof me.parentView.removePictureConfirm === 'function') {
			me.parentView.removePictureConfirm(me)
		}
	},

	openSettings () {
		var me = this
		const { data } = this

		const newWindowCheckbox = EE('input.field-link_target', {
			type: 'checkbox',
			name: 'newWindow'
		})
		if (data.link_target === '_blank') {
			newWindowCheckbox.checked = true
		}

		const dialogContent = DOM.fragment([
			EE('div.bs-ed-form-group', {}, [
				EE('div.bs-ed-form-label', {}, ['代替テキスト']),
				EE('div.bs-ed-form-controls', {}, [
					EE('input.bs-ed-input.field-alt', { value: data.alt }),
					EE('div.bs-ed-input-desc', {}, ['画像の代わりに読み上げられるテキストです'])
				])
			]),
			EE('div.bs-ed-form-group', {}, [
				EE('div.bs-ed-form-label', {}, ['リンクURL']),
				EE('div.bs-ed-form-controls', {}, [
					EE('input.bs-ed-input.field-link_url', { value: data.link_url }),
					EE('label.bs-ed-checkbox', {}, [
						newWindowCheckbox,
						' 新しいウィンドウで開く'
					])
				])
			])
		])

		const altInput = dialogContent.querySelector('.field-alt')
		const linkInput = dialogContent.querySelector('.field-link_url')

		const dialog = new Dialog('画像設定', dialogContent, DialogButton.OKCancel)
		dialog.on('hide', function (retval) {
			if (retval === 'OK') {
				data.link_url = linkInput.value.trim()
				data.link_target = newWindowCheckbox.checked ? '_blank' : null
				data.alt = altInput.value.trim()

				me.dom.classList[me.data.link_url ? 'add' : 'remove']('has-link')
				me.parentView.notifyChange()
			}
		})

		dialog.show()
		altInput.focus()
	}

})

const errorMessageFail = 'アップロードに失敗しました'
const errorMessages = [
	[/\b((invalid|unsupported) file type|not an image)\b/i, '対応している画像形式は JPEG, PNG, GIF です'],
	[/\b(network error)\b/i, `${errorMessageFail}。ネットワークに接続されているかご確認ください`],
	[/\b(invalid response)\b/i, `${errorMessageFail}。サーバまたはネットワークで不具合が発生している可能性があります`],
	[/\b(entity too large)\b/i, 'ファイルサイズの上限を超えたためアップロードできませんでした'],
	[/\b(invalid post)\b/i, `${errorMessageFail}。ログイン状態をご確認ください`],
	[/\b(not save file)\b/i, `${errorMessageFail}。サーバで不具合が発生している可能性があります`]
]
function localizeErrorMessage (message) {
	const localized = errorMessages.find(([re, mes]) => re.test(message))
	return localized ? localized[1] : message
}

export default PictureItem
