import Sortable from 'sortablejs'
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import View from '../View'
import DOM from '../dom/DOM'
import TableRow from './TableRow'

const EE = DOM.create

function Table () {
	var me = this
	me.id = View.register(me)
	me.parentView = null
	me.data = {
		header: null,
		rows: []
	}
	me.dom = null
	me.rowsE = null

	me.initView.apply(me, arguments)
}

View.registerClass('Table', Table, {

	createDOM () {
		var me = this; var containerE; var headerE; var rowsE
		const { id } = this

		if (!me.data.header) {
			me.data.header = new TableRow(me)
		}
		if (!me.data.rows[0]) {
			me.data.rows.push(new TableRow(me))
			me.data.rows.push(new TableRow(me))
		}

		headerE = EE('div.header')
		DOM.append(me.data.header.getDOM(), headerE)

		rowsE = EE('div.rows')
		me.rowsE = rowsE
		me.data.rows.forEach(me.appendView, me)

		containerE = EE('div.bs-ed-view.bs-ed-view-Table', { id }, [
			headerE,
			rowsE,
			EE('div.tool', {}, [
				EE('button.bs-ed-btn.bs-ed-btn-default.bs-ed-btn-xs.bs-ed-act', {
					type: 'button',
					'data-act': 'addRow'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-plus'),
					' 行を追加'
				])
			])
		])

		this.sortable = new Sortable(rowsE, {
			onUpdate: () => this.sortHandler()
		})

		return containerE
	},

	/// @param row: TableRow
	appendView (row) {
		DOM.append(row.getDOM(), this.rowsE)
	},

	/// DOM が並び変わっているので、内部データをそれに合わせる
	sortHandler () {
		var me = this; var mRows = [];

		[].forEach.call(DOM.qs('.bs-ed-view', me.rowsE), function (viewE) {
			var view = View.get(viewE.id)
			if (view) {
				mRows.push(view)
			}
		})

		me.data.rows = mRows
		me.notifyChange()
	},

	addRow () {
		var me = this; var row

		row = new TableRow(me)
		me.data.rows.push(row)
		me.appendView(row)
		me.notifyChange()
	},

	/// @param id: string
	removeRowConfirm (id) {
		const row = View.get(id)
		if (!row) {
			return
		}

		const empty = (str) => typeof str !== 'string' || str.trim() === ''

		// 内容が空ならすぐに削除
		if (empty(row.data.th) || empty(row.data.td)) {
			this.removeRow(row)
			return
		}

		// 入力がある場合は尋ねる
		let dialog = new Dialog('行の削除', 'この行を削除してもよろしいですか？', DialogButton.YesNo)
		dialog.on('hide', retval => {
			if (retval === 'yes') {
				this.removeRow(row)
			}
			dialog = null
		})
		dialog.show()
	},

	/// @param aRow: TableRow
	removeRow (aRow) {
		var me = this; var row; var i; var ri

		i = 0
		ri = null
		while ((row = me.data.rows[i])) {
			if (row.id === aRow.id) {
				aRow.getDOM().classList.add('out')
				ri = i
				break
			}
			i++
		}
		if (ri !== null) {
			me.data.rows.splice(ri, 1)
			me.parentView.notifyChange()
			View.unregister(aRow)

			setTimeout(function () {
				DOM.remove(aRow.getDOM())
			}, 50)
		}
	}

})

export default Table
