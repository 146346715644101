import Sortable from 'sortablejs'

import DOM from '../dom/DOM'
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'

import View from '../View'
import Richtext from './Richtext'

const EE = DOM.create

class Row {
	constructor (cols) {
		this.id = ++Row.nextId
		this.cols = cols
	}

	getData () {
		return {
			cols: this.cols.map(col => col.getData())
		}
	}
}
Row.nextId = 0
Row.wrap = row => (row instanceof Row ? row : new Row(row.cols))

function Tabular () {
	var me = this
	me.id = View.register(me)
	me.parentView = null
	me.data = {
		cells: [], // Row[]
		cols: 3,
		hcols: 0,
		hrows: 0,
		frows: 0
	}
	me.dom = null
	me.rowsE = null

	me.rowEdit = false

	me.initView.apply(me, arguments)
}

View.registerClass('Tabular', Tabular, {

	createDOM () {
		const createButton = (act, label, icon) => {
			return EE('button.bs-ed-btn.bs-ed-btn-default.bs-ed-btn-xs.bs-ed-act', {
				type: 'button',
				tabIndex: '-1',
				'data-act': act
			}, [
				icon ? EE('span.bs-ed-icon.bs-ed-icon-' + icon) : null,
				icon ? ' ' : null,
				label
			])
		}

		const { id, data } = this
		data.cells = data.cells.map(Row.wrap).map(row => {
			// データが煩雑になるのを避けるため View クラスに頼らず自前で wrap する
			row.cols = row.cols.map(col => (col instanceof Richtext) ? col : new Richtext(this, col))
			return row
		})

		const rowsE = EE('div.bs-ed-tbrows')
		const containerE = EE('div.bs-ed-view.bs-ed-view-Tabular', { id }, [
			rowsE,
			EE('div.bs-ed-tbtool', {}, [
				createButton('appendRow', '行を追加', 'plus'),
				this.rowEditBtn = createButton('toggleRowEdit', '行ごとの編集', 'edit'),
				createButton('openSetting', '表の設定', 'cog')
			])
		])

		this.rowsE = rowsE
		this.sortable = new Sortable(this.rowsE, {
			disabled: true,
			animation: 180,
			handle: '.bs-ed-tbrtooli',
			ghostClass: '.bs-ed-tbghost',
			onUpdate: () => this.sortHandler()
		})

		// 初期データを追加
		data.cells.forEach(row => this.insertRowDOM(row))

		// 行がなければ3行用意する
		if (data.cells.length === 0) {
			this.appendRow()
			this.appendRow(true)
			this.appendRow(true)
		}

		this.updateDOM()

		return containerE
	},

	/// @param reverse: boolean
	focus (reverse) {
		if (this.rowEdit) {
			return
		}

		const cells = this.data.cells
		if (cells.length === 0) {
			return
		}
		const row = reverse ? cells[cells.length - 1] : cells[0]
		if (row.cols.length === 0) {
			return
		}
		const col = reverse ? row.cols[row.length - 1] : row.cols[0]
		col.focus()
	},

	/// 行を追加する
	/// @param suppressFocus: boolean 行を追加したときにフォーカスを移動しない
	appendRow (suppressFocus) {
		const { data } = this
		const insertTo = Math.max(0, data.cells.length - data.frows)
		const row = new Row(Array(data.cols).fill(0).map(() => new Richtext(this)))
		data.cells.splice(insertTo, 0, row)
		this.insertRowDOM(row, insertTo)

		if (!suppressFocus) {
			setTimeout(() => row.cols[0].focus())
		}

		this.notifyChange()
	},

	/// @param row: Row
	/// @param insertTo: number
	insertRowDOM (row, insertTo) {
		const { data, rowsE } = this
		const ref = (insertTo == null) ? null : rowsE.childNodes[insertTo]
		const rowE = EE('div.bs-ed-tbrow', { 'data-bs-ed-tbrowid': row.id })
		row.cols.slice(0, data.cols).forEach((col, index) => {
			const colDOM = this.createColDOM(col)
			if (index < data.hcols) {
				colDOM.classList.add('bs-ed-tbth')
			}
			rowE.appendChild(colDOM)
		})

		// 編集用ボタン
		rowE.appendChild(EE('div.bs-ed-tbrtool', {}, [
			EE('div.bs-ed-tbrtooli', {}, [
				EE('button.bs-ed-toolbutton.bs-ed-danger.bs-ed-act', {
					type: 'button',
					tabindex: '-1',
					'data-act': `removeRowConfirm:${row.id}`
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-remove', { title: 'この行を削除する' })
				])
			])
		]))

		rowsE.insertBefore(rowE, ref)
	},

	/// @param col: View
	createColDOM (col) {
		return EE('div.bs-ed-tbcol', {}, [col.getDOM()])
	},

	/// 行ごとの編集モードにする
	toggleRowEdit () {
		const me = this
		const mode = !me.rowEdit
		me.rowEdit = mode
		me.rowsE.classList[mode ? 'add' : 'remove']('bs-ed-tbredit')
		me.rowEditBtn.classList[mode ? 'add' : 'remove']('active')

		const { sortable } = me
		if (mode) {
			me.parentView.blur()
			sortable.option('disabled', false)
		} else {
			sortable.option('disabled', true)
		}
	},

	/// 設定
	openSetting () {
		const { data } = this
		const setting = new TabularSetting(data)
		setting.open(updates => {
			Object.assign(data, updates)
			this.updateDOM()
		})
	},

	/// 設定に応じて DOM を更新
	updateDOM () {
		const { rowsE } = this
		const { cells, cols, hcols, hrows } = this.data
		let { frows } = this.data

		// フッタ行が多すぎる場合は減らす
		if (hrows + frows > cells.length) {
			frows = cells.length - hrows
			this.data.frows = frows
		}

		// 列数をいいがにする
		cells.forEach((row, index) => {
			const rowE = rowsE.childNodes[index]
			if (row.cols.length < cols) {
				const addingCols = Array(cols - row.cols.length).fill(0).map(() => new Richtext(this))
				const refNode = rowE.querySelector('.bs-ed-tbrtool')
				addingCols.forEach(col => {
					row.cols.push(col)
					rowE.insertBefore(this.createColDOM(col), refNode)
				})
			} else if (row.cols.length > cols) {
				const removedCols = row.cols.splice(cols)
				removedCols.forEach(col => {
					col.blur()
					const rte = col.getDOM()
					DOM.remove(rte.parentNode) // .bs-ed-tbcol
					DOM.remove(rte)
				})
			}
		})

		// 見出し行・列を設定する
		cells.forEach((row, vi) => {
			const rowE = rowsE.childNodes[vi]
			row.cols.forEach((col, hi) => {
				const colE = rowE.childNodes[hi]
				const hd = (hi < hcols || vi < hrows || vi >= cells.length - frows)
				colE.classList[hd ? 'add' : 'remove']('bs-ed-tbth')
			})
		})

		this.parentView.notifyChange()
	},

	/// Sortablejs によって DOM が並べ替えられたとき、内部データを更新する
	sortHandler () {
		const { cells } = this.data
		const rowIds = [].map.call(this.rowsE.childNodes, child => child.getAttribute('data-bs-ed-tbrowid'))
			.filter(rowId => rowId != null)
			.map(rowId => parseInt(rowId, 10))
		const newRows = []
		rowIds.forEach(rowId => {
			cells.filter(row => row.id === rowId).some(row => newRows.push(row))
		})
		cells.splice(0, cells.length, ...newRows)

		this.updateDOM()
	},

	/// @param strRowId: string
	removeRowConfirm (strRowId) {
		const rowId = parseInt(strRowId, 10)
		const { cells } = this.data
		const rowIndex = cells.findIndex(row => row.id === rowId)
		if (rowIndex < 0) {
			return
		}

		const dialog = new Dialog('行の削除', 'この行を削除してもよろしいですか？', DialogButton.YesNo)
		dialog.on('hide', retval => {
			if (retval === 'yes') {
				this.removeRow(rowIndex)
			}
		})
		dialog.show()
	},

	/// @param rowIndex: int
	removeRow (rowIndex) {
		const { cells } = this.data
		if (rowIndex < 0 || this.data.length <= rowIndex) {
			throw new Error('Out of range')
		}
		cells.splice(rowIndex, 1)
		DOM.remove(this.rowsE.childNodes[rowIndex])
		this.updateDOM()
	},

	/// tab によるフォーカス移動
	/// @param fromView: View
	/// @param reverse: boolean
	/// @return focusMoved: boolean
	onTab (fromView, reverse) {
		const linearCells = this.data.cells.reduce((flatten, row) => {
			row.cols.forEach(col => flatten.push(col))
			return flatten
		}, [])
		const index = linearCells.findIndex(view => view === fromView)
		if (index < 0) {
			return false
		}
		const toIndex = index + (reverse ? -1 : 1)
		if (toIndex >= 0 && toIndex < linearCells.length) {
			const view = linearCells[toIndex]
			fromView.blur()
			view.focus()
			return true
		} else if (this.parentView) {
			return this.parentView.onTab(this, reverse)
		} else {
			return false
		}
	}

})

class TabularSetting {
	constructor (data) {
		const inputs = [
			{ key: 'cols', label: '列数', min: 1, max: 6, add: EE('div.bs-ed-input-desc') },
			{ key: 'hcols', label: '見出し列数', min: 0, max: 5 },
			{ key: 'hrows', label: 'ヘッダ行数', min: 0, max: data.cells.length - 1 },
			{ key: 'frows', label: 'フッタ行数', min: 0, max: data.cells.length - 1 }
		].map(desc => {
			desc.input = EE('input.bs-ed-input', {
				type: 'number',
				value: data[desc.key],
				step: 1,
				min: desc.min,
				max: desc.max
			})
			desc.form = Dialog.createFormGroup(desc.label, [
				EE('div.bs-ed-input-group', {}, [
					desc.input,
					EE('button.bs-ed-btn.bs-ed-btn-default.bs-ed-num-minus', {}, ['−']),
					EE('button.bs-ed-btn.bs-ed-btn-default.bs-ed-num-plus', {}, ['+'])
				]),
				desc.add || null
			])
			desc.form.classList.add('bs-ed-form-group-inline')
			return desc
		})

		const dialogDOM = EE('div', {}, inputs.map(desc => desc.form))
		const dialog = new Dialog('表の設定', dialogDOM, DialogButton.OKCancel)

		this.dialog = dialog
		this.dialogDOM = dialogDOM
		this.inputs = inputs
		this.prevCols = data.cols
	}

	open (cb) {
		const { dialog, dialogDOM, inputs, prevCols } = this

		// + - button
		const numButtonHandler = (event) => {
			try {
				const button = DOM.closest('.bs-ed-btn', event.target)
				const input = button.parentNode.querySelector('.bs-ed-input')
				const d = button.classList.contains('bs-ed-num-minus') ? -1 : 1
				const min = parseInt(input.getAttribute('min'), 10)
				const max = parseInt(input.getAttribute('max'), 10)
				const val = parseInt(input.value, 10)
				const newVal = Math.max(min, Math.min(max, val + d))
				if (val !== newVal) {
					input.value = newVal
					colsChangeHandler()
				}
			} catch (e) {}
		}

		// cols が変化したときにメッセージを表示
		const colsInputDesc = inputs[0]
		const colsChangeHandler = () => {
			const newCols = parseInt(colsInputDesc.input.value, 10)
			colsInputDesc.add.textContent = (newCols < prevCols) ? `${newCols + 1} 列目以降の内容は削除されます` : ''
		}

		dialogDOM.addEventListener('click', numButtonHandler, false)
		colsInputDesc.input.addEventListener('input', colsChangeHandler, false)

		dialog.on('hide', ret => {
			dialogDOM.removeEventListener('click', numButtonHandler, false)
			colsInputDesc.input.removeEventListener('input', colsChangeHandler, false)

			if (ret !== 'OK') {
				return
			}
			const updates = {}
			inputs.forEach(desc => {
				let val = parseInt(desc.input.value, 10)
				val = (val < desc.min) ? desc.min : val
				val = (val > desc.max) ? desc.max : val
				updates[desc.key] = val
			})
			cb(updates)
		})

		dialog.show()
		setTimeout(() => {
			const firstInput = inputs[0].input
			firstInput.focus()
			firstInput.select()
		})
	}
}

export default Tabular
