import View from '../View'
import DOM from '../dom/DOM'
import { EmbedValidator, EmbedValidatorError } from '../embedValidator'

const EE = DOM.create

/**
 * Twitter のタイムラインを埋め込むためのブロック
 * @see https://publish.twitter.com/
 */
function Twitter () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		code: ''
	}
	me.dom = null
	me.firstInput = null
	me.preview = null
	me.help = null
	me.initView.apply(me, arguments)
}

View.registerClass('Twitter', Twitter, {

	createDOM () {
		const { id, data } = this

		this.preview = EE('div.llp', {}, [
			EE('span.bs-ed-icon.bs-ed-icon-twitter'),
			EE('span.llpd', {}, ['Twitter 埋め込み'])
		])
		this.help = EE('div.llh')
		this.firstInput = EE('input.bs-ed-input-tr.llbb', {
			value: data.code,
			placeholder: 'Twitter の埋め込みコード',
			'bs-ed-var': 'code'
		})

		const dom = EE('div.bs-ed-view.bs-ed-view-Twitter', { id }, [
			EE('div.lli', {}, [
				EE('span.bs-ed-icon.bs-ed-icon-twitter'),
				this.firstInput
			]),
			this.preview,
			this.help
		])

		setTimeout(() => this.updatePreview())

		return dom
	},

	focus () {
		if (this.firstInput) {
			this.firstInput.focus()
		}
	},

	onUpdate (/* key, value, event */) {
		Twitter.__super__.onUpdate.apply(this, arguments)
		this.updatePreview()
	},

	updatePreview () {
		const code = (this.data.code != null) ? this.data.code.trim() : ''

		if (!this.preview) return

		this.setError(false)
		if (code === '') {
			this.preview.classList.add('llph')
			this.dom.classList.remove('llv')
			return
		}

		// ## eg. 1
		// <a class="twitter-timeline" href="https://twitter.com/foobar?ref_src=twsrc%5Etfw">Tweets by foobar</a>
		// <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
		//
		// ## eg. 2
		// <a href="https://twitter.com/foobar?ref_src=twsrc%5Etfw" class="twitter-follow-button"
		//  data-show-count="false">Follow @foobar</a>
		// <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
		try {
			this.data.code = EmbedValidator.validate(code, {
				a: {
					attrs: ['href', 'class'],
					fns: [
						(node) => {
							const href = node.getAttribute('href')
							if (!href) return true
							if (!/^https:\/\/twitter[.]com\b/.test(href)) throw new EmbedValidatorError('Invalid "<a href>" value.')
							return true
						}
					]
				},
				script: {
					attrs: ['src', 'async', 'defer', 'charset', 'crossorigin', 'class'],
					fns: [
						(node) => {
							const src = node.getAttribute('src')
							if (!src) return false
							if (!/^https:\/\/(?:[0-9a-z-]+[.])*twitter[.]com\b/i.test(src)) throw new EmbedValidatorError('Invalid "<script src>" value.')
							// <script> 本文を取り除く
							node.innerHTML = ''
							return true
						}
					]
				}
			})
			this.preview.classList.remove('llph')
			this.dom.classList.add('llv')
		} catch (e) {
			console.warn(e)
			this.setError(true)
			this.data.code = ''
			this.preview.classList.add('llph')
			this.dom.classList.remove('llv')
		}
	},

	setError (cond) {
		const message = 'Twitter の埋め込みコードではありません'
		this.dom.classList[cond ? 'add' : 'remove']('has-error')
		this.help.textContent = cond ? message : ''
	}

})

export default Twitter
