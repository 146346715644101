import Sortable from 'sortablejs'
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import View from '../View'
import DOM from '../dom/DOM'
import File from './File'

const EE = DOM.create

function FileList () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		items: []
	}
	me.dom = null
	me.itemView = null

	me.initView.apply(me, arguments)
}

View.registerClass('FileList', FileList, {

	createDOM () {
		const { id, data } = this

		this.itemContainer = EE('div.items')
		this.addContainer = EE('div.add')
		this.addView = new File(this)
		this.addContainer.appendChild(this.addView.getDOM())

		const E = EE('div.bs-ed-view.bs-ed-view-FileList.llaf', { id }, [
			this.itemContainer,
			this.addContainer
		])
		this.dom = E

		data.items.forEach(this.appendView, this)

		this.sortable = new Sortable(this.itemContainer, {
			// direction: "vertical",
			handle: '.bs-ed-editor-sort-handle',
			animation: 220,
			onUpdate: () => this.sortHandler(),
			onStart: () => E.classList.remove('llaf'),
			onEnd: () => E.classList.add('llaf')
		})

		return E
	},

	appendView (view) {
		this.itemContainer && this.itemContainer.appendChild(view.getDOM())
	},

	sortHandler () {
		// DOM が並び変わっているので、内部データをそれに合わせる
		var me = this; var mItems = [];

		[].forEach.call(me.dom.querySelectorAll('.bs-ed-view'), function (viewE) {
			var view = View.get(viewE.id)
			mItems.push(view)
		})
		me.data.items = mItems.filter(view => view.data.uid != null)
		me.notifyChange()
	},

	notifyChange () {
		if (this.addView.data.uid) {
			this.addItem()
		}
		this.parentView.notifyChange()
	},

	// File でアップロードが完了すると notifyChange() が呼ばれ、addContainer から itemContainer の内容を移す
	addItem () {
		// 内容を移す
		this.data.items.push(this.addView)
		this.itemContainer.appendChild(this.addView.getDOM())

		// 追加用の View を再生成する
		this.addView = new File(this)
		this.addContainer.appendChild(this.addView.getDOM())
	},

	removeItemConfirm (id) {
		var me = this; var item; var dialog
		item = View.get(id)
		if (item) {
			if (!item.data.uid) {
				// ファイルが選択されていない場合はすぐに削除
				me.removeItem(item)
				return
			}

			dialog = new Dialog('ファイルの削除', '“' + item.data.name + '” を削除してもよろしいですか？', DialogButton.YesNo)
			dialog.on('hide', (retval) => {
				if (retval === 'yes') {
					me.removeItem(item)
				}
				dialog = null
			})
			dialog.show()
		}
	},

	removeItem (aItem) {
		var me = this; var item; var i; var ri

		i = 0
		ri = null
		while ((item = me.data.items[i])) {
			if (item.id === aItem.id) {
				aItem.getDOM().classList.add('out')
				ri = i
				break
			}
			i++
		}
		if (ri !== null) {
			me.data.items.splice(ri, 1)
			me.parentView.notifyChange()
			View.unregister(aItem)

			setTimeout(() => DOM.remove(aItem.getDOM()), 50)
		}
	}
})

export default FileList
