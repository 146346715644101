var util = {

	/// @param needle: any
	/// @param haystack: [any]
	inArray (needle, haystack) {
		var i
		for (i = 0; i < haystack.length; i++) {
			if (needle === haystack[i]) {
				return true
			}
		}
		return false
	},

	/// obj に props のプロパティをコピーする
	/// @param obj: object
	/// @param props: object
	addProps (obj, props) {
		Object.keys(props).forEach(function (key) {
			obj[key] = props[key]
		})
	},

	/// クラスの継承もどき
	/// extend() from CoffeeScript
	/// @param child: function
	/// @param parent: function
	/// @return function
	extend (child, parent) {
		Object.keys(parent).forEach(function (key) {
			child[key] = parent[key]
		})

		function Ctor () { this.constructor = child }
		Ctor.prototype = parent.prototype
		child.prototype = new Ctor()
		child.__super__ = parent.prototype

		return child
	}

}

export default util
