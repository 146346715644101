import DOM from './dom/DOM'
import Evt from './dom/Event'

let pm = null

/// ポップアップを管理する
function PopupManager (window) {
	var me = this

	me.window = window
	me.activePopup = null
}

PopupManager.prototype = {

	OpenerClass: 'bs-ed-popup-opener',

	init () {
		var me = this

		Evt.on(me.window, 'mousedown', me)
		Evt.on(me.window, 'touchstart', me)
	},

	isActive (popup) {
		return this.activePopup === popup
	},

	/// ポップアップを開く。すでに開いているものは閉じられる
	/// @param popup: Popup
	open (popup) {
		var me = this

		if (me.activePopup) {
			me.activePopup.close()
		}

		popup.open()

		me.activePopup = popup
	},

	/// ポップアップを閉じる
	close () {
		var me = this; var opener

		if (me.activePopup) {
			me.activePopup.close()
			me.activePopup = null
		}

		opener = DOM.q('.' + me.OpenerClass)
		if (opener) {
			opener.classList.remove(me.OpenerClass)
		}
	},

	/// @private
	/// @param event: Event
	handleEvent (event) {
		var me = this; var E

		if (!me.activePopup) {
			return
		}

		// ポップアップ以外をクリック/タップした場合はポップアップを閉じる
		E = me.activePopup.getDOM()
		if (!DOM.closest(E, event.target) && !DOM.closest('.' + me.OpenerClass, event.target)) {
			me.close()
		}
	}

}

pm = new PopupManager(window)
Evt.on(document, 'DOMContentLoaded', function () {
	pm.init()
})

export default pm
