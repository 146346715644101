import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import View from '../View'
import DOM from '../dom/DOM'

const EE = DOM.create

const allowElements = 'address|article|aside|footer|header|h1|h2|h3|h4|h5|h6|hgroup|nav|section|div|p|ul|ol|li|dl|dt|dd|pre|blockquote|figure|figcaption|hr|a|br|hr|span|em|strong|small|big|abbr|q|cite|ins|del|sup|sub|ruby|rt|rp|rb|code|time|img|audio|video|picture|source|table|caption|thead|tbody|tfoot|tr|th|td'.split('|')
const allowAttributes = 'class|id|title|style|cite|href|target|alt|src|srcset|media|sizes|type|width|height|border|scope|colspan|rowspan'.split('|')

function HTML () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		html: ''
	}
	me.dom = null
	me.previewE = null
	me.initView.apply(me, arguments)
}

View.registerClass('HTML', HTML, {

	createDOM () {
		var me = this; var containerE; var previewE

		previewE = DOM.create('div.preview')
		previewE.innerHTML = me.data.html
		me.previewE = previewE

		containerE = EE('div.bs-ed-view.bs-ed-view-HTML', {
			id: me.id
		}, [
			previewE,
			EE('div.tools', {}, [
				EE('button.bs-ed-btn.bs-ed-btn-default.bs-ed-btn-sm.bs-ed-act', {
					type: 'button',
					'data-act': 'editHTML'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-edit'),
					DOM.text(' HTMLを編集')
				])
			])
		])

		return containerE
	},

	editHTML () {
		var me = this; var dialogContent; var dialog
		const { data } = this

		dialogContent = EE('div.bs-ed-dialog-form-group', {}, [
			EE('textarea.bs-ed-input.field-html', { rows: 10 }, [data.html])
		])

		dialog = new Dialog('HTML編集', dialogContent, DialogButton.OKCancel)
		dialog.on('hide', function (retval) {
			var html, errors, errorDialog
			if (retval === 'OK') {
				errors = []
				html = dialogContent.querySelector('.field-html').value.trim()
				html = validateHTML(html, errors)
				if (errors.length) {
					errorDialog = new Dialog('警告', DOM.fragment([
						EE('p', {}, ['次の問題を自動修正しました。']),
						EE('ul', {}, errors.map(err => EE('li', {}, [err.toString()])))
					]), DialogButton.OK)
					errorDialog.show()
				}
				me.data.html = html
				me.previewE.innerHTML = html

				me.parentView.notifyChange()
			}
		})
		dialog.show()
	}

})

/// @param $
/// @param [string] &
/// @return string
function validateHTML (html, errors) {
	var E = document.createElement('div')
	E.innerHTML = html
	cleanElement(E, errors)
	return E.innerHTML
}

/// @param Element &
/// @param [string] &
function cleanElement (E, errors) {
	var tagName, attrs, i, attrName, parent;

	(function (E) {
		for (var node = E.firstChild; node; node = node.nextSibling) {
			if (DOM.isElement(node)) {
				cleanElement(node, errors)
			}
		}
	})(E)

	tagName = E.nodeName.toLowerCase()
	if (!allowElements.some(function (name) { return name === tagName })) {
		// 許可していない要素は、その内容に置き換える
		parent = E.parentNode
		while (E.firstChild) {
			parent.insertBefore(E.firstChild, E)
		}
		parent.removeChild(E)
		errors.push('許可されていないタグ “' + tagName + '” は削除されました。')
		return
	}

	// 許可していない属性を削除する
	attrs = E.attributes
	for (i = attrs.length - 1; i >= 0; i--) {
		attrName = attrs[i].name.toLowerCase()
		if (!allowAttributes.some(function (name) { return name === attrName })) {
			attrs.removeNamedItem(attrName)
			errors.push('許可されていない属性 “' + attrName + '”(' + tagName + ') は削除されました。')
		}
	}
}

export default HTML
