export function copyToClipboard (text) {
	const temp = document.createElement('div')
	Object.assign(temp.style, {
		position: 'absolute',
		top: '-2em',
		left: '0',
		right: '0',
		opacity: '0',
		lineHeight: '1'
	})
	temp.textContent = text

	document.body.appendChild(temp)
	document.getSelection().selectAllChildren(temp)
	document.execCommand('copy')
	document.body.removeChild(temp)
}