import DOM from '../dom/DOM'

const instances = []
DOM.ready(() => {
	instances.forEach(inst => inst.create())
})

class Backdrop {
	constructor () {
		this.visible = false
		this.ready = false
		this.wrapE = null
		this.E = DOM.create('div.bs-ed-backdrop')

		instances.push(this)
		this.create()
	}

	create () {
		if (this.ready) {
			return
		}
		const wrapE = document.body
		if (!wrapE) {
			return
		}
		wrapE.appendChild(this.E)
		this.wrapE = wrapE
		this.ready = true
	}

	show () {
		this.setVisible(true)
	}

	hide () {
		this.setVisible(false)
	}

	setVisible (visible) {
		if (this.visible === visible) {
			return
		}
		this.visible = visible

		if (!this.ready) {
			return
		}

		const ecl = this.E.classList
		if (visible) {
			ecl.add('active')
		} else {
			ecl.add('out')
			setTimeout(() => {
				ecl.remove('out')
				ecl.remove('active')
			}, 220)
		}
	}

	destroy () {
		const index = instances.findIndex(inst => inst === this)
		if (index >= 0) {
			instances.splice(index, 1)
		}

		if (this.ready) {
			this.wrapE.removeChild(this.E)
		}
	}
}

export default Backdrop
