import View from '../View'
import DOM from '../dom/DOM'
import DOMUtil from '../dom/DOMUtil'

function TableRow () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		th: '',
		td: ''
	}
	me.dom = null
	me.thTE = null
	me.tdTE = null
	me.initView.apply(me, arguments)
}

View.registerClass('TableRow', TableRow, {

	createDOM: function () {
		var me = this; var containerE; var thTE; var tdTE

		thTE = DOM.create('textarea.bs-ed-input-tr', { 'bs-ed-var': 'th' }, [DOM.text(me.data.th)])
		me.thTE = thTE

		tdTE = DOM.create('textarea.bs-ed-input-tr', { 'bs-ed-var': 'td' }, [DOM.text(me.data.td)])
		me.tdTE = tdTE

		containerE = DOM.create('div.bs-ed-view.bs-ed-view-TableRow', { id: me.id }, [
			DOM.create('div.th', {}, [thTE]),
			DOM.create('div.td', {}, [tdTE]),
			DOM.create('div.row-tool', {}, [
				DOM.create('button.bs-ed-toolbutton.bs-ed-danger.bs-ed-act', {
					type: 'button',
					tabindex: '-1',
					'data-act': 'removeRowConfirm:' + me.id
				}, [
					DOM.create('span.bs-ed-icon.bs-ed-icon-remove', { title: 'この行を削除する' })
				]),
				DOM.create('span.bs-ed-toolbutton.bs-ed-editor-sort-handle', {}, [
					DOM.create('span.bs-ed-icon.bs-ed-icon-toggle-v', { title: 'ドラッグで並べ替え' })
				])
			])
		])

		setTimeout(function () {
			DOMUtil.adjustHeight(me.thTE)
			DOMUtil.adjustHeight(me.tdTE)
		})

		return containerE
	},

	getTexts: function () {
		var me = this; var texts = [];

		['th', 'td'].forEach(function (key) {
			if (me.data[key] !== '') {
				texts.push(me.data[key])
			}
		})

		return texts.join('\n')
	},

	onUpdate: function (/* key, value, event */) {
		var me = this

		TableRow.__super__.onUpdate.apply(me, arguments)
		if (me.thTE) {
			DOMUtil.adjustHeight(me.thTE)
		}
		if (me.tdTE) {
			DOMUtil.adjustHeight(me.tdTE)
		}
	}

})

export default TableRow
