import View from '../View'
import DOM from '../dom/DOM'
import { EmbedValidator, EmbedValidatorError } from '../embedValidator'

const EE = DOM.create

/**
 * Facebook のページプラグインを埋め込むためのブロック
 * @see https://developers.facebook.com/docs/plugins/page-plugin
 */
function Facebook () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		script: '',
		code: ''
	}
	me.dom = null
	me.firstInput = null
	me.lastInput = null
	me.preview = null
	me.help = null
	me.initView.apply(me, arguments)
}

View.registerClass('Facebook', Facebook, {

	createDOM () {
		const { id, data } = this

		this.preview = EE('div.llp', {}, [
			EE('span.bs-ed-icon.bs-ed-icon-facebook'),
			EE('span.llpd', {}, ['Facebook 埋め込み'])
		])
		this.help = EE('div.llh')

		this.firstInput = EE('input.bs-ed-input-tr.llbb', {
			value: data.script,
			placeholder: '<div id="fb-root"></div> で始まるコード',
			'bs-ed-var': 'script'
		})
		this.lastInput = EE('input.bs-ed-input-tr.llbb', {
			value: data.code,
			placeholder: '<div class="fb-page" ...  で始まるコード',
			'bs-ed-var': 'code'
		})

		const dom = EE('div.bs-ed-view.bs-ed-view-Facebook', { id }, [
			EE('div.lli', {}, [
				EE('span.bs-ed-icon.bs-ed-icon-facebook'),
				this.firstInput
			]),
			EE('div.lli', {}, [
				EE('span.bs-ed-icon.bs-ed-icon-facebook'),
				this.lastInput
			]),
			this.preview,
			this.help
		])

		setTimeout(() => this.updatePreview())

		return dom
	},

	focus (reverse) {
		if (!reverse && this.firstInput) {
			this.firstInput.focus()
		}
		if (reverse && this.lastInput) {
			this.lastInput.focus()
		}
	},

	onUpdate (/* key, value, event */) {
		Facebook.__super__.onUpdate.apply(this, arguments)
		this.updatePreview()
	},

	updatePreview () {
		const script = (this.data.script != null) ? this.data.script.trim() : ''
		const code = (this.data.code != null) ? this.data.code.trim() : ''

		if (!this.preview) return

		this.setError(false)
		if (script === '' || code === '') {
			this.preview.classList.add('llph')
			this.dom.classList.remove('llv')
			return
		}

		try {
			// ## eg. script
			// <div id="fb-root"></div>
			// <script async defer crossorigin="anonymous" src="https://connect.facebook.net/ja_JP/
			// sdk.js#xfbml=1&autoLogAppEvents=1&version=v5.0&appId=000000000"></script>
			this.data.script = EmbedValidator.validate(script, {
				script: {
					attrs: ['src', 'async', 'defer', 'charset', 'crossorigin', 'class'],
					fns: [
						(node) => {
							const src = node.getAttribute('src')
							if (!src) return false
							if (!/^https:\/\/(?:[0-9a-z-]+[.])*facebook[.](?:com|net)\b/i.test(src)) throw new EmbedValidatorError('Invalid "<script src>" value.')
							// <script> 本文を取り除く
							node.innerHTML = ''
							return true
						}
					]
				},
				div: {
					attrs: ['id'],
					fns: [
						(node) => node.parentNode.removeChild(node)
					]
				}
			})

			// ## eg. code
			// <div class="fb-page" data-href="https://www.facebook.com/foobar/" data-tabs="timeline"
			//  data-width="" data-height="" data-small-header="false" data-adapt-container-width="true"
			//  data-hide-cover="false" data-show-facepile="true">
			//   <blockquote cite="https://www.facebook.com/foobar/" class="fb-xfbml-parse-ignore">
			//     <a href="https://www.facebook.com/foobar/">Foobar Page</a>
			//   </blockquote>
			// </div>
			this.data.code = EmbedValidator.validate(code, {
				div: {
					attrs: ['class', 'data-href', 'data-width', 'data-show-text']
				},
				blockquote: {
					attrs: ['class', 'cite']
				},
				a: {
					attrs: ['class', 'href']
				},
				p: {
					attrs: []
				}
			})

			this.preview.classList.remove('llph')
			this.dom.classList.add('llv')
		} catch (e) {
			console.warn(e)
			this.setError(true)
			this.data.script = ''
			this.data.code = ''
			this.preview.classList.add('llph')
			this.dom.classList.remove('llv')
		}
	},

	setError (cond) {
		const message = 'Facebook の埋め込みコードではありません'
		this.dom.classList[cond ? 'add' : 'remove']('has-error')
		this.help.textContent = cond ? message : ''
	}

})

export default Facebook
