import DOM from './dom/DOM'

/// ポップアップ
/// createDOM() をオーバーライドして使う
function Popup () {
	var me = this

	me.E = null
}

Popup.Class = 'bs-ed-popup'
Popup.ActiveClass = 'bs-ed-active'

Popup.prototype = {

	open () {
		this.E.classList.add(Popup.ActiveClass)
	},

	close () {
		this.E.classList.remove(Popup.ActiveClass)
	},

	getDOM () {
		var me = this

		if (!me.E) {
			me.E = me.createDOM()
		}
		return me.E
	},

	createDOM () {
		return DOM.create('div.' + Popup.Class)
	}

}

export default Popup
