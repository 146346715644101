import View from '../View'
import DOM from '../dom/DOM'
import { EmbedValidator, EmbedValidatorError } from '../embedValidator'

const EE = DOM.create

function GMap () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		code: ''
	}
	me.dom = null
	me.firstInput = null
	me.preview = null
	me.help = null
	me.initView.apply(me, arguments)
}

View.registerClass('GMap', GMap, {

	createDOM () {
		const { id, data } = this

		this.preview = EE('div.llp')
		this.help = EE('div.llh')
		this.firstInput = EE('input.bs-ed-input-tr.llbb', {
			value: data.code,
			placeholder: 'Google マップの埋め込み HTML',
			'bs-ed-var': 'code'
		})

		const dom = EE('div.bs-ed-view.bs-ed-view-GMap', { id }, [
			EE('div.lli', {}, [
				EE('span.bs-ed-icon.bs-ed-icon-map'),
				this.firstInput
			]),
			this.preview,
			this.help
		])

		setTimeout(() => this.updatePreview())

		return dom
	},

	focus () {
		if (this.firstInput) {
			this.firstInput.focus()
		}
	},

	onUpdate (/* key, value, event */) {
		GMap.__super__.onUpdate.apply(this, arguments)
		this.updatePreview()
	},

	updatePreview () {
		const code = (this.data.code != null) ? this.data.code.trim() : ''

		if (!this.preview) return

		this.setError(false)
		this.preview.innerHTML = ''
		if (code === '') {
			this.preview.classList.add('llph')
			this.dom.classList.remove('llv')
			return
		}

		// ## eg.
		// <iframe src="https://www.google.com/maps/embed?pb=..."
		//  width="400" height="300" frameborder="0"
		//  style="border:0;"
		//  allowfullscreen=""
		//  aria-hidden="false"
		//  tabindex="0"
		//  loading="lazy"
		// ></iframe>
		try {
			let hasElement = false
			this.data.code = EmbedValidator.validate(code, {
				iframe: {
					attrs: ['src', 'width', 'height', 'frameborder', 'style', 'allowfullscreen', 'aria-hidden', 'tabindex', 'loading'],
					fns: [
						(node) => {
							hasElement = true
							const src = node.getAttribute('src')
							if (!src) return false
							if (!/^https:\/\/www[.]google[.]com\/maps\/embed\?/.test(src)) throw new EmbedValidatorError('Invalid "<iframe src>" value.')
							return true
						}
					]
				}
			})
			if (!hasElement) throw new EmbedValidatorError('No iframes found.')
			this.preview.innerHTML = this.data.code
			this.preview.classList.remove('llph')
			this.dom.classList.add('llv')
		} catch (e) {
			this.setError(true)
			this.data.code = ''
			this.notifyChange()
		}
	},

	setError (cond) {
		const message = 'Google マップの埋め込みコードではありません'
		this.dom.classList[cond ? 'add' : 'remove']('has-error')
		this.help.textContent = cond ? message : ''
	}
})

export default GMap
