import View from '../View'
import DOM from '../dom/DOM'
import { copyToClipboard } from '../ui/clipboard'
window.cp = copyToClipboard

const EE = DOM.create

/** @var {string[]} */
const usedHash = []

function createNewHash () {
	let hash
	do {
		hash = createHash()
	} while (usedHash.includes(hash))
	return hash
}

function createHash (length = 10) {
	const charList = 'abcdefghijklmnopqrstuvwxyz'
	let s = ''
	for (let i = 0; i < length; i++) {
		s += charList[~~(Math.random() * charList.length)]
	}
	return s
}

function Anchor (parentView, obj) {
	var me = this
	const hash = (obj && obj.hash) ? obj.hash : createNewHash()
	usedHash.push(hash)

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		hash,
	}
	me.dom = null
	me.cbmElem = null
	me.cbmTimer = null
	me.initView.apply(me, arguments)
}

View.registerClass('Anchor', Anchor, {

	createDOM () {
		const { id, data } = this

		return EE('div.bs-ed-view.bs-ed-view-Anchor', { id }, [
			EE('label.bs-ed-view-Anchor-wrap', {}, [
				EE('span', {}, ['#']),
				EE('input.bs-ed-view-Anchor-input', {
					type: 'text',
					value: data.hash,
					pattern: '^[A-Za-z][0-9A-Za-z-]*$',
					'bs-ed-var': 'hash'
				})
			]),
			EE('button.bs-ed-btn-tr.bs-ed-act', {
				type: 'button',
				tabindex: '-1',
				'data-act': 'copyToClipboard'
			}, [
				EE('span.bs-ed-icon.bs-ed-icon-copy'),
				(this.cbmElem = document.createTextNode('リンクをコピー'))
			])
		])
	},

	copyToClipboard () {
		if (this.data.hash) {
			copyToClipboard('#' + this.data.hash)

			this.cbmElem.textContent = 'コピーしました'
			clearTimeout(this.cbmTimer)
			this.cbmTimer = setTimeout(() => {
				this.cbmElem.textContent = 'リンクをコピー'
			}, 1500)
		}
	}
})

export default Anchor
