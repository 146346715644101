import Exif from "./Exif";
import ImageResizer from "./ImageResizer";
import supportsImageOrientation from "./supportsImageOrientation";

function doNothing() {}

export default class ImageUploader {

	constructor(opts) {
		Object.assign(this, {
			file: opts.file,
			onComplete: typeof opts.complete === "function" ? opts.complete : doNothing,
			onError: typeof opts.error === "function" ? opts.error : doNothing,
			maxSize: opts.maxSize || 1200,
			uploadTo: zzl.uploadImageTo || opts.uploadTo || null,
		});
		this.readFile();
	}
	
	readFile() {
		var me = this, readerB, readerS, imageData;
		
		imageData = { bin: null, str: null };
		
		readerB = new FileReader;
		readerB.onload = function(event) {
			imageData.bin = event.target.result;
			if (imageData.str) {
				me.readComplete(imageData);
			}
		};
		readerB.readAsArrayBuffer(me.file);
		
		readerS = new FileReader;
		readerS.onload = function(event) {
			imageData.str = event.target.result;
			if (imageData.bin) {
				me.readComplete(imageData);
			}
		};
		readerS.readAsDataURL(me.file);
	}
	
	readComplete(imageData) {
		var me = this, orientation, image;

		if (imageData.str.indexOf("data:image/") !== 0) {
			me.onError("Invalid file type");
			return;
		}

		orientation = supportsImageOrientation() ?
			1 : Exif.getOrientation(imageData.bin);

		image = new Image;
		image.onload = () => me.resize(image, orientation);
		image.onerror = () => me.onError("Unsupported file type");
		image.src = imageData.str;
	}
	
	resize(image, orientation) {
		var me = this, src, dest, dataURL;
		
		src = me.drawImageToCanvas(image, orientation);
		dest = me.getResizedCanvas(src.width, src.height);
		ImageResizer.draw(src, dest, 0, 0, src.width, src.height, dest.width, dest.height);

		dataURL = dest.toDataURL("image/jpeg", me.quality);
		src = dest = null;

		me.upload(dataURL);
	}
	
	getResizedCanvas(w, h) {
		var me = this, canvas;
		
		if (w > me.maxSize) {
			h *= me.maxSize / w;
			w = me.maxSize;
		}
		if (h > me.maxSize) {
			w *= me.maxSize / h;
			h = me.maxSize;
		}
		
		canvas = document.createElement("canvas");
		canvas.width = Math.round(w);
		canvas.height = Math.round(h);

		return canvas;
	}
	
	drawImageToCanvas(image, orientation) {
		var canvas, ctx, width, height;

		width = image.width;
		height = image.height;

		canvas = document.createElement("canvas");
		if (orientation < 5) {
			canvas.width = width;
			canvas.height = height;
		}
		else {
			canvas.width = height;
			canvas.height = width;
		}
		
		ctx = canvas.getContext("2d");
		ctx.save();

		switch (orientation) {
			case 2:
				// horizontal flip
				ctx.translate(width, 0);
				ctx.scale(-1, 1);
				break;
			case 3:
				// 180 rotate left
				ctx.translate(width, height);
				ctx.rotate(Math.PI);
				break;
			case 4:
				// vertical flip
				ctx.translate(0, height);
				ctx.scale(1, -1);
				break;
			case 5:
				// vertical flip + 90 rotate right
				ctx.rotate(0.5 * Math.PI);
				ctx.scale(1, -1);
				break;
			case 6:
				// 90 rotate right
				ctx.rotate(0.5 * Math.PI);
				ctx.translate(0, -height);
				break;
			case 7:
				// horizontal flip + 90 rotate right
				ctx.rotate(0.5 * Math.PI);
				ctx.translate(width, -height);
				ctx.scale(-1, 1);
				break;
			case 8:
				// 90 rotate left
				ctx.rotate(-0.5 * Math.PI);
				ctx.translate(-width, 0);
				break;
			default:
				break;
		}
		
		ctx.drawImage(image, 0, 0, image.width, image.height);
		ctx.restore();
		ctx = null;
		
		return canvas;
	}

	upload(dataURL) {
		const {uploadTo, file, onComplete, onError} = this;
		if (uploadTo == null) {
			return;
		}

		let prevResponse = null;

		fetch(uploadTo, {
			method: "POST",
			cache: "no-cache",
			credentials: "same-origin",
			body: this.postData({
				_VALIDPOST: zzl.env.post_token || zzl.env.session_id,
				content: dataURL,
				name: file.name,
			}),
		})
		.catch(err => { throw new Error("Network error") })
		.then(res => (prevResponse = res, res.json()))
		.catch(err => {
			if (prevResponse && prevResponse.status === 413) throw new Error("Request Entity Too Large");
			throw new Error("Invalid response");
		})
		.then(json => {
			if (prevResponse && prevResponse.ok) onComplete(json);
			else onError(json.message);
		})
		.catch(err => onError(err.message));
	}

	postData(data) {
		const formData = new FormData();
		Object.keys(data).forEach(key => formData.append(key, data[key]));
		return formData;
	}
}
