import GMReady from '../plugin/google-maps-ready'
import View from '../View'
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import DOM from '../dom/DOM'

const EE = DOM.create

let GM = null
let simpleMapStyle

const mapDefaults = {
	lat: 36.578081,
	lng: 136.647835,
	zoom: 14
}

const geocodeErrorDialog = new Dialog('お探しの住所は見つかりませんでした', null, DialogButton.OK)

function Map () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		lat: mapDefaults.lat,
		lng: mapDefaults.lng,
		zoom: mapDefaults.zoom
	}
	me.dom = null
	me.map = null
	me.marker = null
	me.geocoder = null
	me.geocodeDialog = null
	me.geocodeDialogContent = null

	me.initView.apply(me, arguments)
}

View.registerClass('Map', Map, {

	createDOM () {
		const { id } = this

		const E = EE('div.bs-ed-view.bs-ed-view-Map', { id }, [
			EE('div.map'),
			EE('div.map-tools', {}, [
				EE('button.bs-ed-btn.bs-ed-btn-default.bs-ed-act', {
					title: '住所から場所を設定',
					tabIndex: '-1',
					'data-act': 'searchLocation'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-search')
				])
			])
		])

		this.geocodeDialogContent = EE('div.bs-ed-dialog-form-group', {}, [
			EE('div.control-label', {}, ['住所']),
			EE('div.controls', {}, [
				EE('input.bs-ed-input.field-address', { value: '' })
			])
		])

		this.geocodeDialog = new Dialog('住所から検索', this.geocodeDialogContent, DialogButton.OKCancel)
		this.geocodeDialog.on('hide', retval => {
			if (retval === 'OK') {
				this.geocodeAddress(this.geocodeDialogContent.querySelector('.field-address').value.trim())
			}
		})

		return E
	},

	/// DOM が生成されてから Google Maps を初期化する
	DOMReady () {
		GMReady(googleMaps => {
			GM = googleMaps

			simpleMapStyle = new GM.StyledMapType([
				{
					featureType: 'poi',
					elementType: 'labels',
					stylers: [
						{ visibility: 'off' }
					]
				}
			], {
				name: 'Simple Map'
			})

			this.initMap()
		})
	},

	/// 地図・マーカーを初期化する
	initMap () {
		var me = this; var pos

		pos = new GM.LatLng(me.data.lat, me.data.lng)

		me.geocoder = new GM.Geocoder()

		// 地図を初期化
		me.map = new GM.Map(me.dom.querySelector('.map'), {
			mapTypeId: GM.MapTypeId.ROADMAP,
			center: pos,
			zoom: me.data.zoom,
			mapTypeControl: false,
			fullscreenControl: false,
			rotateControl: false,
			streetViewControl: false,
			scrollwheel: false
		})

		// マップタイプを追加して設定
		me.map.mapTypes.set('simple_map', simpleMapStyle)
		me.map.setMapTypeId('simple_map')

		// 地図のイベントハンドラを設定
		const saveValuesHandler = () => me.saveValues()
		GM.event.addListener(me.map, 'drag', saveValuesHandler)
		GM.event.addListener(me.map, 'dragend', saveValuesHandler)
		GM.event.addListener(me.map, 'zoom_changed', saveValuesHandler)

		// 位置を示すマーカー
		me.marker = new GM.Marker({
			map: me.map,
			position: pos,
			title: 'ドラッグして移動することができます',
			draggable: true,
			bouncy: false
		})
		GM.event.addListener(me.marker, 'mouseup', saveValuesHandler)
		GM.event.addListener(me.marker, 'mousedown', saveValuesHandler)
		GM.event.addListener(me.marker, 'drag', saveValuesHandler)
	},

	/// 地図の移動などで座標/ズームが変化した場合に呼び出す
	saveValues () {
		var me = this; var pos
		pos = me.marker.getPosition()
		me.data.lat = pos.lat()
		me.data.lng = pos.lng()
		me.data.zoom = me.map.getZoom()
		me.parentView.notifyChange()
	},

	/// 場所の検索ダイアログを表示
	searchLocation () {
		var me = this; var inputE
		me.geocodeDialog.show()

		inputE = me.geocodeDialogContent.querySelector('.field-address')
		if (inputE) {
			inputE.focus()
			inputE.select()
		}
	},

	///
	geocodeAddress (address) {
		var me = this
		me.geocoder.geocode({ address: address }, function (results, status) {
			if (status === GM.GeocoderStatus.OK) {
				var location = results[0].geometry.location
				me.map.setCenter(location)
				me.map.setZoom(mapDefaults.zoom)
				me.marker.setPosition(location)
				me.data.lat = location.lat()
				me.data.lng = location.lng()
				me.data.zoom = mapDefaults.zoom
				me.saveValues()
			} else {
				status = localizeGeocodeStatus(status)
				geocodeErrorDialog.setSubtitle(status)
				geocodeErrorDialog.show()
			}
		})
	}

})

function localizeGeocodeStatus (status) {
	switch (status) {
	case 'ZERO_RESULTS':
		return '場所が見つかりません。別のキーワードでお試しください。'
	case 'OVER_QUERY_LIMIT':
	case 'REQUEST_DENIED':
		return '現在一時的にこの機能をご利用いただけません'
	case 'INVALID_REQUEST':
		return '内部エラーが発生しました'
	case 'UNKNOWN_ERROR':
		return '座標の取得に失敗しました。もう一度検索すると成功する可能性があります。'
	}
}

export default Map
