import DOM from './DOM'

const blockSelector = DOM.blockElements.join(',')
// const paragraphSelector = DOM.paragraphElements.join(',')
const listItemSelector = 'li'

// const ABORT = () => { throw new Error('aborted') }

/// E が selector と attributes を持つ要素かどうかを返す
/// @param E: Element
/// @param selector: string
/// @param attributes: [string : string]
/// @return boolean
function isSameStyle (E, selector, attributes) {
	var prop, value, style, elem

	if (!DOM.is(selector, E)) {
		return false
	}

	if (!attributes) {
		return true
	}

	for (prop in attributes) {
		if (prop === 'style' || !Object.prototype.hasOwnProperty.call(attributes, prop)) {
			continue
		}
		value = E.getAttribute(prop)
		if (value !== attributes[prop]) {
			return false
		}
	}

	if ((style = attributes.style)) {
		elem = DOM.create('div', { style: style })
		for (prop in style) {
			if (!Object.prototype.hasOwnProperty.call(style, prop)) {
				continue
			}
			if (elem.style[prop] !== E.style[prop]) {
				return false
			}
		}
		elem = null
	}

	return true
}

// NOTE: Selection.prototype に追加する
const SelectionFormat = {

	/// 選択範囲をブロック要素としてフォーマット/解除
	/// @param selector: string
	/// @return modifiedRange: Range?
	toggleFormatBlock (selector) {
		var me = this; var range; var blockEs; var allMatched; var newRange

		range = me.getRange()
		if (!range) {
			return null
		}

		// スタイルを適用する範囲を求める
		blockEs = me.getBlocksFromRange(range) || []
		if (blockEs.length === 0) {
			return null
		}
		allMatched = blockEs.every(function (blockE) {
			return !!DOM.closest(selector, blockE)
		})

		// 全てのブロックがマッチしない場合はリストを解除
		if (!allMatched) {
			range = me.unformatListElement()
		}

		// スタイルを適用
		newRange = me.formatBlock(allMatched ? 'p' : selector)

		// リスト直下に p 要素が単独である場合は p 要素を除去
		if (allMatched) {
			blockEs = me.getBlocksFromRange(newRange)
			blockEs.forEach(function (blockE) {
				var parent = blockE.parentNode
				if (blockE.nodeName === 'P' && DOM.is(DOM.listItemElements, parent)) {
					while (blockE.firstChild) {
						parent.appendChild(blockE.firstChild)
					}
					parent.removeChild(blockE)
				}
			})
		}

		return newRange
	},

	/// 選択範囲をブロック要素としてフォーマット
	/// @param selector: string
	/// @param attributes: [string : string]
	/// @return modifiedRange: Range?
	formatBlock (selector, attributes) {
		var me = this; var range; var blockEs; var firstBlockE; var lastBlockE; var anchorE; var newRange

		range = me.getRange()
		if (!range) {
			return null
		}

		blockEs = me.getBlocksFromRange(range)
		// console.log(`formatBlock(${selector}): ` + blockEs.map(e => `${e.nodeName}[${DOM.index(e)}]`).join(", "));
		if (!blockEs) {
			if (range.startContainer === me.body) {
				return null
			}

			// 選択範囲がブロック要素内にない場合、選択範囲のあとにブロック要素を追加する
			anchorE = DOM.create(selector, attributes)
			DOM.insertAfter(anchorE, range.startContainer)

			newRange = me.createRange()
			newRange.setStart(anchorE, 0)
			newRange.setEnd(anchorE, 0)
			return newRange
		}

		blockEs.forEach(function (blockE) {
			var newE, paragraphE

			newE = DOM.create(selector, attributes)

			paragraphE = DOM.closest(DOM.paragraphElements, blockE)
			if (paragraphE) {
				while (paragraphE.firstChild) {
					newE.appendChild(paragraphE.firstChild)
				}
				DOM.replace(paragraphE, newE)
			} else {
				while (blockE.firstChild) {
					newE.appendChild(blockE.firstChild)
				}
				blockE.appendChild(newE)
			}

			// ブロック全体を選択するため
			if (!firstBlockE) {
				firstBlockE = newE
			}
			lastBlockE = newE
		})

		// 変更したブロック要素全体を指す Range を生成する
		anchorE = DOM.create('span', {
			class: '__anchor'
		})
		lastBlockE.appendChild(anchorE)

		newRange = me.createRange()
		newRange.setStart(firstBlockE, 0)
		newRange.setEndBefore(anchorE)

		DOM.remove(anchorE)

		return me.normalizeRange(newRange)
	},

	/// 選択範囲をリストとしてフォーマット
	/// @param selector: string
	/// @param attributes: [string : string]
	/// @return modifiedRange: Range
	formatListElement (selector, attributes) {
		var me = this; var listTag; var range; var blockEs; var prevParentE; var containerE; var contentE; var firstBlockE; var lastBlockE; var newRange

		listTag = 'li'

		// 先にリストを解除しておく
		range = me.unformatListElement()
		if (!range) {
			return
		}

		blockEs = me.getBlocksFromRange(range)
		// console.log("getBlocksFromRange()", range);
		// console.log(`formatListElement(${selector}): ` + blockEs.map(E => `${E.nodeName} [${DOM.index(E)}]`).join(", "));
		if (!blockEs) {
			// 選択範囲がブロック要素内にない場合、選択範囲のあとにブロック要素を追加する
			containerE = DOM.create(selector, attributes)
			contentE = DOM.create(listTag)
			containerE.appendChild(contentE)
			DOM.insertAfter(containerE, range.startContainer)

			newRange = me.createRange()
			newRange.setStart(contentE, 0)
			newRange.setEnd(contentE, 0)
			return me.normalizeRange(newRange)
		}

		blockEs.forEach(function (blockE) {
			if (!blockE.parentNode) {
				return
			}

			// 親が共通の要素は、同じ ul/ol 要素に入れる
			// ∵ 選択範囲は連続しているため
			if (prevParentE !== blockE.parentNode) {
				containerE = DOM.create(selector, attributes)
				blockE.parentNode.insertBefore(containerE, blockE)

				prevParentE = blockE.parentNode
			}

			// 既存の要素をリストに入れていく
			contentE = DOM.create(listTag)
			if (blockE.tagName === 'P') {
				while (blockE.firstChild) {
					contentE.appendChild(blockE.firstChild)
				}
				DOM.remove(blockE)
			} else {
				contentE.appendChild(blockE)
			}
			containerE.appendChild(contentE)

			// Range の生成に使用
			if (!firstBlockE) {
				firstBlockE = contentE
			}
			lastBlockE = contentE
		})

		// 変更したブロック要素全体を指す Range を生成する
		newRange = me.createRange()
		newRange.setStart(firstBlockE, 0)
		newRange.setEnd(lastBlockE, lastBlockE.childNodes.length)

		return me.normalizeRange(newRange)
	},

	/// 選択範囲のリスト要素を消去し、普通の段落にする
	/// @return modifiedRange: Range
	unformatListElement () {
		var me = this; var newRange

		/// @param contentE: Element
		/// @param insertBeforeE: Element
		function unformatBlock (contentE, insertBeforeE) {
			var childE, paragraphE

			while ((childE = contentE.firstChild)) {
				if (DOM.is(DOM.blockElements, childE)) {
					DOM.insertBefore(childE, insertBeforeE)
				} else {
					if (!paragraphE) {
						paragraphE = DOM.create('p')
						DOM.insertBefore(paragraphE, insertBeforeE)
					}
					DOM.append(childE, paragraphE)
				}

				// Range の生成に使用
				if (!firstBlockE) {
					firstBlockE = childE
				}
				lastBlockE = childE
			}

			DOM.remove(contentE)
		}

		const range = me.getRange()
		if (!range) {
			return
		}

		const blockEs = me.getBlocksFromRange(range)
		if (!blockEs) {
			return range
		}

		// Range の生成に使用
		let firstBlockE = null
		let lastBlockE = null
		let prevContainerE = null
		let dupContainerE = null
		let anchorE = null

		blockEs.forEach(function (blockE) {
			const contentE = DOM.closest(listItemSelector, blockE)
			if (!contentE) {
				if (!firstBlockE) {
					firstBlockE = blockE
				}
				lastBlockE = blockE
				return
			}

			const containerE = contentE.parentNode
			if (prevContainerE !== containerE) {
				// ul/ol 要素が変わったら前の目印を削除する
				if (anchorE) {
					DOM.remove(anchorE)
				}
				if (prevContainerE && !prevContainerE.firstChild) {
					DOM.remove(prevContainerE)
				}
				if (dupContainerE && !dupContainerE.firstChild) {
					DOM.remove(dupContainerE)
				}

				// 目印をつける
				anchorE = DOM.create('li.__spilt')
				DOM.insertBefore(anchorE, contentE)

				// ul/ol 要素を分割する
				dupContainerE = containerE.cloneNode(false)
				DOM.insertAfter(dupContainerE, containerE)

				// 間に挿入
				unformatBlock(contentE, dupContainerE)

				prevContainerE = containerE
			} else {
				unformatBlock(contentE, dupContainerE)
			}
		})

		// ul/ol 要素が変わったら前の目印を削除する
		if (anchorE) {
			if (prevContainerE && dupContainerE) {
				while (anchorE.nextSibling) {
					DOM.append(anchorE.nextSibling, dupContainerE)
				}
			}
			DOM.remove(anchorE)
		}
		if (prevContainerE && !prevContainerE.firstChild) {
			DOM.remove(prevContainerE)
		}
		if (dupContainerE && !dupContainerE.firstChild) {
			DOM.remove(dupContainerE)
		}

		// 変更したブロック要素全体を指す Range を生成する
		newRange = me.createRange()
		newRange.setStart(firstBlockE, 0)

		let endPoint = lastBlockE
		while (endPoint.lastChild) {
			endPoint = endPoint.lastChild
		}
		newRange.setEnd(endPoint, DOM.isTextNode(endPoint) ? endPoint.length : 0)

		return newRange
	},

	/// range が属するブロック要素のリストを返す
	/// @param range: Range
	/// @return blocks: [Element]
	getBlocksFromRange (range) {
		const blocks = []

		if (!range.startContainer || !range.endContainer) {
			return blocks
		}

		// 探索
		const endNode = range.endContainer
		let node = range.startContainer
		let lastBlock = null
		let fromInline = true // インライン要素から探索を始めた場合に使用する

		while (node && node !== endNode) {
			const block = (() => {
				if (DOM.is(blockSelector, node)) {
					fromInline = false
					return node
				}
				return fromInline ? DOM.closest(blockSelector, node) : null
			})()
			if (block && block !== lastBlock) {
				blocks.push(block)
				lastBlock = block
			}
			node = DOM.traverse(node)
		}

		// 最後のノードを処理
		if (node) {
			const block = DOM.is(blockSelector, node) ? node : DOM.closest(blockSelector, node)
			if (block && block !== lastBlock) {
				blocks.push(block)
			}
		}

		return blocks
	},

	/// 選択範囲をインライン要素としてフォーマット/解除
	/// @param selector: string
	/// @param attributes: [string : string]
	/// @param modifiedRange: Range?
	toggleFormatInline (selector, attributes) {
		// 選択範囲
		const range = this.getRange()
		if (!range) {
			return null
		}

		// 未選択状態のとき、カーソル位置にスタイルが適用されていればスタイルを解除する
		if (range.collapsed) {
			return this.unformatCursorPointFromRange_(range, selector, attributes)
		}

		// 選択範囲に含まれるテキストノード
		const textNodes = this.getTextNodesFromRange(range)
		if (textNodes.length < 1) {
			return null
		}
		// console.log(`toggleFormatInline(${selector}): textNodes = `, textNodes);

		// 選択範囲に含まれるテキストノードがすべて指定したスタイルを持っているかどうか調べる
		const allFormatted = textNodes.every(node => {
			const styledNode = DOM.closest(selector, node)
			if (!styledNode) return false
			return isSameStyle(styledNode, selector, attributes)
		})

		// const nodesDesc = textNodes.map(n => `  ${n.parentNode.nodeName}[${DOM.index(n)}] > #text ${n.nodeValue}`).join("\n");
		// console.log(`toggleFormatInline(${selector}): isAllFormatted = ${allFormatted} //\n` + nodesDesc);

		this.splitNodes_(textNodes, range)

		if (allFormatted) {
			return this.unformatInlineFromNodes_(textNodes, selector, attributes)
		} else {
			const newRange = this.formatInlineFromNodes_(textNodes, selector, attributes)
			const start = newRange.startContainer
			const end = newRange.endContainer

			this.normalizeFormatFromRange_(newRange)

			// [Firefox][Chrome][Edge] DOM を操作すると Range が変更されてしまうので作り直す
			return this.createRangeFromTextNodes_(start, end)
		}
	},

	/// @param selector: string
	/// @param attributes: [string : string]
	/// @return Range
	formatInline (selector, attributes) {
		// 選択範囲
		const range = this.getRange()
		if (!range) {
			return null
		}

		// 選択範囲に含まれるテキストノード
		const textNodes = this.getTextNodesFromRange(range)
		if (textNodes.length < 1) {
			return null
		}

		this.splitNodes_(textNodes, range)

		const newRange = this.formatInlineFromNodes_(textNodes, selector, attributes)
		const start = newRange.startContainer
		const end = newRange.endContainer

		this.normalizeFormatFromRange_(newRange)

		// [Firefox][Chrome][Edge] DOM を操作すると Range が変更されてしまうので作り直す
		return this.createRangeFromTextNodes_(start, end)
	},

	/// @param selector: string
	/// @param attributes: [string : string]
	/// @return Range
	unformatInline (selector, attributes) {
		// 選択範囲
		const range = this.getRange()
		if (!range) {
			return null
		}

		// 選択範囲に含まれるテキストノード
		const textNodes = this.getTextNodesFromRange(range)
		if (textNodes.length < 1) {
			return null
		}

		this.splitNodes_(textNodes, range)
		return this.unformatInlineFromNodes_(textNodes, selector, attributes)
	},

	/// 2つのテキストノード start, end から Range オブジェクトを作成する
	/// @param start: Text
	/// @param end: Text
	/// @return Range
	createRangeFromTextNodes_ (start, end) {
		const range = this.createRange()
		range.setStart(start, 0)
		range.setEnd(end, end.length)
		return this.normalizeRange(range)
	},

	/// nodes および外包するインライン要素の途中に range の始点終点を含まないようノードを分割する
	/// @param nodes: [Text]
	/// @param range: Range
	/// @return [Text]
	splitNodes_ (nodes, range) {
		const start = range.startContainer
		const end = range.endContainer
		const soff = range.startOffset
		let eoff = range.endOffset
		{
			// range の始点を処理
			const len = DOM.isTextNode(start) ? start.length : start.childNodes.length
			if (soff === 0) {
				this.splitElements_(start, false)
			} else if (soff === len) {
				this.splitElements_(start, true)
			} else if (!DOM.isTextNode(start)) {
				this.splitElements_(start.childNodes[soff], false)
			} else {
				if (start !== nodes[0]) {
					console.warn('Unexpected args [start] (nodes, range) = ', nodes, range)
				}
				// テキストノードを分割
				const text = start.nodeValue
				const exText = DOM.text(text.substring(0, soff))
				start.nodeValue = text.substring(soff, text.length)
				start.parentNode.insertBefore(exText, start)
				// start = end の場合は内容を書き換えたためにオフセットがずれるので調整する
				if (start === end) {
					eoff -= soff
				}
				this.splitElements_(start, false)
			}
		}
		{
			// range の終点を処理
			const len = DOM.isTextNode(end) ? end.length : end.childNodes.length
			if (eoff === len) {
				this.splitElements_(end, true)
			} else if (eoff === 0) {
				this.splitElements_(end, false)
			} else if (!DOM.isTextNode(end)) {
				this.splitElements_(end.childNodes[eoff], false)
			} else {
				if (end !== nodes[nodes.length - 1]) {
					console.warn('Unexpected args [end] (nodes, range) = ', nodes, range)
				}
				// テキストノードを分割
				const text = end.nodeValue
				const exText = DOM.text(text.substring(eoff))
				end.nodeValue = text.substring(0, eoff)
				end.parentNode.insertBefore(exText, end.nextSibling)
				this.splitElements_(end, true)
			}
		}
		return nodes
	},

	/// インライン要素を target の前 (splitAfter = false) または後 (splitAfter = true) で分割する
	/// @param target: Node
	/// @param splitAfter: boolean
	splitElements_ (target, splitAfter) {
		let parent = target.parentNode
		let node = target
		// console.log(`  splitElements(${exText.nodeValue}, ${toEnd}): elem = ${elem.nodeName}, exNode = ${elem.nodeName}`);
		while (!DOM.blockElements.includes(parent.nodeName.toLowerCase())) {
			const clen = parent.childNodes.length
			const index = DOM.index(node)
			if ((!splitAfter && index > 0) || (splitAfter && index < clen - 1)) {
				const exParent = parent.cloneNode(false)
				const [sliceBegin, sliceEnd] = splitAfter ? [index + 1, clen] : [0, index]
				const exChildren = [].slice.call(parent.childNodes, sliceBegin, sliceEnd)
				exChildren.forEach(child => exParent.appendChild(child))
				parent.parentNode.insertBefore(exParent, splitAfter ? parent.nextSibling : parent)
				// console.log(`  split `, elem, " to ", exParent, node);
			}
			node = parent
			parent = parent.parentNode
		}
	},

	/// テキストノード nodes にスタイル (selector, attributes) を適用する
	/// @param nodes: [Text]
	/// @param selector: string
	/// @param attributes: [string : string]
	/// @return Range
	formatInlineFromNodes_ (nodes, selector, attributes) {
		// 外包しているインライン要素を取得
		const getOuterInlineElement = (aNode) => {
			let node = aNode
			while (!DOM.blockElements.includes(node.parentNode.nodeName.toLowerCase())) {
				node = node.parentNode
			}
			return node
		}

		// できるだけ外側からまとめて囲む
		const parts = nodes.reduce((parts, node) => {
			const outerInline = getOuterInlineElement(node)
			const prevPart = (parts.length > 0) ? parts[parts.length - 1] : []
			const prev = (prevPart.length > 0) ? prevPart[prevPart.length - 1] : null
			if (prev !== outerInline) {
				if (!prev || prev.nextSibling !== outerInline) {
					parts.push([outerInline])
				} else {
					prevPart.push(outerInline)
				}
			}
			// console.log(`node, parts = `, node, parts);
			return parts
		}, [])
		// console.log("parts are", parts);

		parts.forEach(part => {
			const before = part[part.length - 1]
			const wrapper = DOM.create(selector, attributes)
			before.parentNode.insertBefore(wrapper, before)
			part.forEach(node => wrapper.appendChild(node))
		})

		// 変更した範囲を返す
		return this.createRangeFromTextNodes_(nodes[0], nodes[nodes.length - 1])
	},

	/// テキストノード nodes からスタイル (selector, attributes) を削除する
	/// @param nodes: [Text]
	/// @param selector: string
	/// @param attributes: [string : string]
	/// @return Range
	unformatInlineFromNodes_ (nodes, selector, attributes) {
		nodes.map(node => DOM.closest(selector, node))
			.filter(wrapE => wrapE && isSameStyle(wrapE, selector, attributes))
			.forEach(wrapE => DOM.unwrap(wrapE))

		// 変更した範囲を返す
		return this.createRangeFromTextNodes_(nodes[0], nodes[nodes.length - 1])
	},

	/// カーソル位置の要素がフォーマットされていれば解除する
	/// @param range: Range
	/// @param selector: string
	/// @param attributes: [string : string]
	/// @return Range?
	unformatCursorPointFromRange_ (range, selector, attributes) {
		const wrapE = DOM.closest(selector, range.startContainer)
		if (!wrapE || !isSameStyle(wrapE, selector, attributes)) {
			return
		}

		const [start, soff, end, eoff] = (() => {
			if (wrapE.firstChild) {
				return [wrapE.firstChild, 0, wrapE.lastChild, DOM.isTextNode(wrapE.lastChild) ? wrapE.lastChild.length : wrapE.lastChild.childNodes.length]
			} else if (wrapE.nextSibling) {
				return [wrapE.nextSibling, 0, wrapE.nextSibling, 0]
			} else {
				const index = DOM.index(wrapE)
				return [wrapE.parentNode, index, wrapE.parentNode, index]
			}
		})()

		DOM.unwrap(wrapE)

		const newRange = this.createRange()
		newRange.setStart(start, 0)
		newRange.setEnd(end, eoff)
		return this.normalizeRange(newRange)
	},

	/// インラインフォーマットを正規化する
	/// @param range: Range
	normalizeFormatFromRange_ (range) {
		const selectors = ['strong', 'big', 'small', 'del', 'ins', 'span.bs-ed-color', 'a[href]']
		const blocks = this.getBlocksFromRange(range)

		const styleDesc = (E, selector) => {
			switch (selector) {
			case 'span.bs-ed-color': {
				return (E.style && E.style.color) ? `span.bs-ed-color - ${E.style.color}` : 'span.bs-ed-color'
			}
			case 'a[href]': {
				return E.getAttribute('href') ? `a[href=${E.getAttribute('href')}]` : 'a[href]'
			}
			default: {
				return selector
			}
			}
		}

		selectors.forEach(selector => {
			blocks.forEach(block => {
				// 多重になったフォーマットを削除する
				// NOTE: フォーマットを適用するときは外側から囲むようにしているので、深い位置のノードのほうが古い
				[].slice.call(block.querySelectorAll(selector + ' ' + selector), 0)
					.forEach(elem => DOM.unwrap(elem));

				// 連続する要素をまとめる
				[].slice.call(block.querySelectorAll(selector + ' + ' + selector), 0)
					.filter(elem => styleDesc(elem, selector) === styleDesc(elem.previousSibling, selector))
					.forEach(elem => {
						const prev = elem.previousSibling
						while (elem.firstChild) {
							prev.appendChild(elem.firstChild)
						}
						DOM.remove(elem)
					})
			})
		})
	},

	/// @param range: Range
	/// @return [Text]
	getTextNodesFromRange (range) {
		const textNodes = []

		const end = (() => {
			const end = range.endContainer
			const eoff = range.endOffset
			if (!DOM.isTextNode(end) && eoff > 0) {
				let node = end.childNodes[eoff - 1]
				while (node.lastChild) {
					node = node.lastChild
				}
				return node
			}
			return end
		})()

		let node = range.startContainer
		if (DOM.isTextNode(node) && range.startOffset === node.length) {
			node = DOM.traverse(node)
		}

		while (node && node !== end) {
			if (DOM.isTextNode(node)) {
				textNodes.push(node)
			}
			node = DOM.traverse(node)
		}
		if (textNodes[textNodes.length - 1] !== end && DOM.isTextNode(end)) {
			textNodes.push(end)
		}

		return textNodes
	}

}

export default SelectionFormat
