import View from '../View'
import DOM from '../dom/DOM'
import Richtext from './Richtext'
import PictureItem from './PictureItem'

const EE = DOM.create

function FloatPicture () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		richtext: null,
		position: 'right',
		picture: null
	}
	me.dom = null
	me.toggleBtn = null

	me.initView.apply(me, arguments)
}

View.registerClass('FloatPicture', FloatPicture, {

	createDOM () {
		var me = this; var containerE

		// オブジェクトがなければ生成
		if (!me.data.richtext) {
			me.data.richtext = new Richtext(me)
		}
		if (!me.data.picture) {
			me.data.picture = new PictureItem(me)
		}

		me.toggleBtn = EE('button.ctgl.bs-ed-btn.bs-ed-btn-default.bs-ed-act', {
			type: 'button',
			title: '図とテキストを入れ替える',
			tabindex: '-1',
			'data-act': 'togglePosition'
		}, [DOM.text('切り替え')])

		containerE = EE('div.bs-ed-view.bs-ed-view-FloatPicture', {
			id: me.id
		}, [
			EE('div.cpic', {}, [
				EE('div.cpbx', {}, [me.data.picture.getDOM()]),
				me.toggleBtn
			]),
			EE('div.ctxt', {}, [me.data.richtext.getDOM()])
		])

		containerE.classList.add(me.data.position === 'right' ? 'right' : 'left')

		return containerE
	},

	focus () {
		if (this.data.richtext) {
			this.data.richtext.focus()
		}
	},

	/// 左右切り替え
	togglePosition () {
		var me = this

		me.toggleBtn.blur()

		if (me.data.position === 'left') {
			me.data.position = 'right'
			me.dom.classList.add('right')
			me.dom.classList.remove('left')
		} else {
			me.data.position = 'left'
			me.dom.classList.add('left')
			me.dom.classList.remove('right')
		}
		me.parentView.notifyChange()
	}

})

export default FloatPicture
