class DialogButton {
	constructor (props) {
		this.label = typeof props.label === 'string' ? props.label : ''
		this.value = String(typeof props.value === 'undefined' ? '' : props.value)
		this.style = typeof props.style === 'string' ? props.style : ''
	}
}

DialogButton.wrap = props => props instanceof DialogButton ? props : new DialogButton(props)

/// 標準的なボタンをセットしておく
DialogButton.OK = [
	{ label: 'OK', value: 'OK', style: 'primary' }
].map(DialogButton.wrap)

DialogButton.OKCancel = [
	{ label: 'OK', value: 'OK', style: 'primary' },
	{ label: 'キャンセル', value: 'cancel', style: 'default' }
].map(DialogButton.wrap)

DialogButton.YesNo = [
	{ label: 'はい', value: 'yes', style: 'primary' },
	{ label: 'いいえ', value: 'no', style: 'default' }
].map(DialogButton.wrap)

export default DialogButton
