import DOM from './DOM'

var d = document

/// 管理しているイベント
/// events[index].E, events[index].type, events[index].handler
/// @var [[string : any]]
var events = []

var Event = {

	/// @param E: Element
	/// @param type: string
	/// @param data: any?
	/// @return notCancelled: boolean
	fire (E, type, data) {
		var event

		if (!E) {
			E = d
		}

		// テキストノードやコメントノードの場合はイベントを発生させない
		if (DOM.isTextNode(E) || DOM.isCommentNode(E)) {
			return
		}

		event = d.createEvent('HTMLEvents')
		event.initEvent(type, true, true)

		// 独自プロパティを定義
		event.data = data

		return E.dispatchEvent(event)
	},

	/// @param E: Element
	/// @param type: string
	/// @param handler: function
	on (E, type, handler) {
		E.addEventListener(type, handler, false)
		events.push({
			E: E,
			type: type,
			handler: handler
		})
	},

	/// @param E: Element
	/// @param type: string?
	/// @param handler: function?
	off (E, type, handler) {
		var index, event, matched

		for (index = 0; index < events.length; index++) {
			event = events[index]
			matched = (event.E === E)
			if (type != null) {
				matched = (matched && event.type === type)
			}
			if (handler != null) {
				matched = (matched && event.handler === handler)
			}
			if (matched) {
				E.removeEventListener(event.type, event.handler, false)
				events.splice(index, 1)
				index--
			}
		}
	}

}

export default Event
