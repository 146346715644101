/* global google */
/*
	Google Maps が読み込まれるのを待って、初期化関数を呼ぶためのライブラリ

	// 使い方
	import GMReady from "google-maps-ready";
	GMReady(GM => {...});

	初期化関数は GMReady() を呼び出した順に処理される
 */

const initializers = []
let initializerRegistered = false

/// 初期化関数を登録する
/// @param initializer: function
function addInitializer (initializer) {
	if (typeof initializer !== 'function') {
		throw new TypeError('initializer must be a function')
	}

	// すでに Google Maps が読み込まれていれば initializer を実行
	if (isGoogleMapsLoaded()) {
		setTimeout(() => initializer(google.maps))
		return
	}

	if (initializers.some(fn => fn === initializer)) {
		return
	}
	initializers.push(initializer)

	initListener()
}

/// 現在 → DOMContentLoaded → window.onload のタイミングで window.google.maps をチェックする
function initListener () {
	if (isGoogleMapsLoaded()) {
		callInitializers()
		return
	}

	if (initializerRegistered) {
		return
	}
	document.addEventListener('DOMContentLoaded', documentReady, false)
	window.addEventListener('load', documentReady, false)
	initializerRegistered = true
}

function documentReady () {
	if (isGoogleMapsLoaded()) {
		callInitializers()
		document.removeEventListener('DOMContentLoaded', documentReady, false)
		window.removeEventListener('load', documentReady, false)
	}
}

/// @return boolean
function isGoogleMapsLoaded () {
	return window.google && google.maps
}

function callInitializers () {
	initializers.forEach(fn => fn(google.maps))
	initializers.splice(0, initializers.length)
}

// Expose
export default addInitializer
