import DOM from './dom/DOM'
import Popup from './Popup'
import ToolbarButton from './ToolbarButton'
import LinkEditor from './formatter/LinkEditor'

const colorSpanClass = 'bs-ed-color'

function Formatter () {
}

Formatter.prototype = {

	/// @param editor: Editor
	/// @param style: string
	/// @return updated: boolean
	toggleBlock (editor, style) {
		var newRange

		newRange = editor.selection.toggleFormatBlock(style)
		if (!newRange) {
			return false
		}

		// スタイルを適用した範囲を選択
		editor.selection.setRange(newRange)
		editor.updateRichtext()
		return true
	},

	/// @param editor: Editor
	/// @param style: string
	/// @return updated: boolean
	toggleList (editor, style) {
		var selection, range, blockEs, allMatched, newRange

		selection = editor.selection
		range = selection.getRange()
		if (!range) {
			return false
		}

		// スタイルを適用する範囲を求める
		blockEs = selection.getBlocksFromRange(range) || []
		allMatched = blockEs.every(function (blockE) {
			return !!DOM.closest(style, blockE)
		})

		// スタイルを適用
		if (!allMatched) {
			newRange = selection.formatListElement(style)
		} else {
			newRange = selection.unformatListElement()
		}

		// スタイルを適用した範囲を選択
		selection.setRange(newRange)
		editor.updateRichtext()
		return true
	},

	/// @param editor: Editor
	/// @param style: string
	/// @return updated: boolean
	toggleInline (editor, style) {
		var newRange

		newRange = editor.selection.toggleFormatInline(style)
		if (!newRange) {
			return false
		}

		editor.selection.setRange(newRange)
		editor.updateRichtext()
		return true
	},

	/// @param colors: string[]
	/// @return Popup
	createColorMenu (colors) {
		var me = this; var dropdown

		dropdown = new Popup()
		dropdown.createDOM = function () {
			return DOM.create('div.bs-ed-toolbar-dropdown.bs-ed-textcolor-dropdown.' + Popup.Class, {}, colors.map(function (color) {
				var button = new ToolbarButton({
					createDOM () {
						var buttonE = DOM.create('span.bs-ed-textcolor-dropdown-item.' + ToolbarButton.ButtonClass, {
							style: { backgroundColor: color }
						})
						return DOM.create('span.' + ToolbarButton.ItemClass, {
							id: this.id
						}, [buttonE])
					},
					onclick (editor) {
						me.setColor(editor, color)
						dropdown.close()
					}
				})
				return button.getDOM()
			}))
		}

		return dropdown
	},

	/// @param editor: Editor
	/// @param color: string
	/// @return updated: boolean
	setColor (editor, color) {
		var selection, newRange

		selection = editor.selection

		if (editor.options.colors && color === editor.options.colors[0]) {
			// 標準の色の場合は、色の変更を解除する
			newRange = selection.unformatInline('span.' + colorSpanClass)
		} else {
			newRange = selection.toggleFormatInline('span.' + colorSpanClass, { style: { color: color } })
		}

		if (!newRange) {
			return false
		}

		// スタイルを適用した範囲を選択
		selection.setRange(newRange)
		editor.updateRichtext()
		return true
	},

	/// @param editor: Editor
	/// @return updated: boolean
	editLink (editor) {
		const { activeView } = editor
		LinkEditor.open(editor, () => {
			editor.activeView = activeView
			editor.focus()
		})
		return false
	}

}

export default Formatter
