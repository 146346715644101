import View from '../View'
import DOM from '../dom/DOM'
import Evt from '../dom/Event'
import KeyEventWrap from '../dom/KeyEvent'
import { validSelectors } from '../Editor'

const EE = DOM.create

const globalEvents = ['mousedown', 'mouseup', 'touchstart']
const localEvents = ['keydown', 'keyup', 'paste', 'input', 'focus', 'paste', 'compositionstart', 'compositionend']

// import Inspector from "../plugin/RichtextInspector";

// リッチテキストエディターの改行時の挙動が変更され、<br> の代わりに <div> が生成されます | Firefox サイト互換性情報
// https://www.fxsitecompat.com/ja/docs/2017/rich-text-editor-s-newline-behaviour-has-been-changed-now-generates-div-instead-of-br/
// document.execCommand("DefaultParagraphSeparator", false, "p");

// ゼロ幅スペース
const ZWSP = '\u200B'

const blockElementSelector = DOM.blockElements.join(',')

function Richtext () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		html: ''
	}
	me.dom = null
	me.editareaE = null
	me.focused = false

	me.initView.apply(me, arguments)

	me.editor = me.closest('Editor')
	me.selection = me.editor.selection
	me.mobileTouchModified = false

	me.IM_active = false

	// me.inspector = new Inspector(me.editorareaE, me.selection);
}

View.registerClass('Richtext', Richtext, {

	createDOM () {
		var me = this; var containerE; var editareaE

		editareaE = EE('div.bs-ed-root.bs-ed-focusarea')
		editareaE.innerHTML = me.data.html
		me.editareaE = editareaE

		containerE = EE('div.bs-ed-view.bs-ed-view-Richtext', {
			id: me.id
		}, [
			editareaE
		])

		return containerE
	},

	focus () {
		var me = this

		if (!me.editareaE) {
			return
		}

		me.focused = true
		me.mousedownOnThis = true
		// console.log(`Richtext.focused = ${this.focused}`);

		me.selection.setRoot(me.editareaE)
		me.editareaE.contentEditable = true
		setTimeout(() => me.editareaE.focus())
		me.dom.classList.add('active')

		globalEvents.forEach(function (eventType) {
			Evt.on(document, eventType, me)
		})

		localEvents.forEach(function (eventType) {
			Evt.on(me.dom, eventType, me)
		})

		me.mobileTouchModified = true
		me.IM_active = false

		setTimeout(() => me.maySelectionChange())
	},

	blur () {
		var me = this

		if (!me.editareaE) {
			return
		}

		me.focused = false
		me.mousedownOnThis = false
		// console.log(`Richtext.focused = ${this.focused}`);

		me.maySelectionChange()

		clearTimeout(me.updateTimer)

		me.editareaE.blur()
		me.editareaE.contentEditable = false
		me.dom.classList.remove('active')

		globalEvents.forEach(eventType => Evt.off(document, eventType, me))
		localEvents.forEach(eventType => Evt.off(me.dom, eventType, me))

		me.updateInternalData()
	},

	createFirstParagraph () {
		var me = this; var p; var range

		p = EE('p')
		me.editareaE.appendChild(p)

		range = me.selection.createRange()
		range.setStart(p, 0)
		range.setEnd(p, 0)
		me.selection.setRange(range)
	},

	getTexts () {
		var texts = []; var node; var text

		this.getDOM() // create this.editareaE

		node = this.editareaE.firstChild
		while (node) {
			text = (node.textContent || '').trim()
			if (text !== '') {
				texts.push(text)
			}
			node = node.nextSibling
		}
		return this.cleanTexts(texts.join('\n'))
	},

	/// innerHTML を内部データに反映する
	updateInternalData () {
		const frag = DOM.create('div')
		frag.innerHTML = this.editareaE.innerHTML;

		[].forEach.call(frag.querySelectorAll('br'), br => {
			const parent = br.parentNode
			if (br === parent.lastChild && br !== parent.firstChild) {
				DOM.remove(br)
			}
		})

		this.data.html = this.cleanTexts(frag.innerHTML)
		this.notifyChange()
	},

	cleanTexts (str) {
		return str.replace(ZWSP, '')
	},

	delayedUpdateInternalData () {
		const updateDelay = 250
		if (this.updateTimer) {
			clearTimeout(this.updateTimer)
		}
		this.updateTimer = setTimeout(() => this.updateInternalData(), updateDelay)
	},

	// ================================================================
	// Apply styles

	toggleBlock (style) {
		var me = this; var newRange

		newRange = me.selection.toggleFormatBlock(style)
		if (!newRange) {
			me.focus()
			return
		}

		me.selection.setRange(newRange)
		me.focus()
		me.updateInternalData()
	},

	/// @private
	/// @param event: Event
	handleEvent (event) {
		var method = 'on' + event.type
		if (typeof this[method] === 'function') {
			this[method](event)
		}
	},

	onkeydown (event) {
		const kev = new KeyEventWrap(event)
		const mod = kev.modifier
		if (!this.IM_active && kev.code === 'Tab' && mod <= 1) {
			const focusMoved = this.parentView.onTab(this, mod === 1)
			if (focusMoved) {
				event.preventDefault()
			}
		}
	},

	onkeyup (event) {
		if (this.IM_active) {
			return
		}
		this.maySelectionChange()
	},

	oncompositionstart () {
		this.IM_active = true
	},

	oncompositionend () {
		this.IM_active = false
	},

	ontouchstart (event) {
		var me = this

		if (!event.touches || event.touches.length !== 1) {
			return
		}

		me.mobileTouchModified = true
	},

	onmousedown (event) {
		var me = this

		if (DOM.closest(me.editareaE, event.target)) {
			me.mousedownOnThis = true
		} else {
			me.mousedownOnThis = false
		}
	},

	onmouseup (event) {
		if (this.mousedownOnThis) {
			setTimeout(() => this.maySelectionChange())
		}
	},

	onpaste (/* event */) {
		setTimeout(() => this.cleanContent())
	},

	/// ルートにテキストノードがあれば p 要素で包む。
	/// Enter キーが押下されたあとに実行される
	fixParagraph () {
		const range = this.selection.getRange()
		if (!range || !range.collapsed) {
			return
		}

		const wrapper = this.editareaE
		const end = range.endContainer
		const nextSibling = end.nextSibling
		const block = DOM.closest(blockElementSelector, end)
		let cursor = end
		let off = range.endOffset

		if (block === wrapper) {
			// wrapper に直接カーソルがある場合、p を作ってテキストノードを押し込む
			const p = DOM.create('p')
			wrapper.insertBefore(p, nextSibling)
			if (DOM.isTextNode(end)) {
				p.appendChild(end)
			}
		} else if (/^div$/i.test(end.nodeName) && end.parentNode === wrapper) {
			// wrapper になぜか div ができた場合、p に置き換える (Firefox)
			const p = DOM.create('p')
			wrapper.insertBefore(p, end)
			while (end.firstChild) {
				p.appendChild(end.firstChild)
			}
			DOM.remove(end)
			cursor = p
			off = 0
		}

		// nextSibling が空のブロックなら削除する
		if (nextSibling && DOM.is(blockElementSelector, nextSibling) && DOM.isEmptyRichtext(nextSibling)) {
			DOM.remove(nextSibling)
		}

		// 選択し直す
		const newRange = this.selection.createRange()
		newRange.setStart(cursor, off)
		newRange.setEnd(cursor, off)
		this.selection.setRange(newRange)
	},

	maySelectionChange (donguri) {
		var me = this

		if (!me.selection) {
			return
		}

		me.selection.updateBookmark()

		// ツールバー更新のためエディタに選択範囲の変更を通知する
		// ただし、エディタにフォーカスしている場合のみ
		if (me.editor && me.focused) {
			me.editor.selectionChange(me)
		}

		me.fixParagraph()

		// me.inspector.update();

		me.delayedUpdateInternalData()
	},

	/// ペーストした内容をフィルタリングする
	/// Editor.onpaste() から呼ばれる
	cleanContent () {
		var me = this

		clean(me.editareaE.firstChild)
		me.fixParagraph()

		/// @param node: Node
		function clean (node) {
			var nextNode

			while (node) {
				nextNode = node.nextSibling

				switch (node.nodeType) {
				case 1: // Element
					clean(node.firstChild)
					cleanElement(node)
					break
				case 8: // Comment
					DOM.remove(node)
					break
				default: // Text and others
					break
				}

				node = nextNode
			}
		}

		/// @param elem: Element
		function cleanElement (elem) {
			const selector = validSelectors.find(q => DOM.is(q, elem))
			if (!selector) {
				DOM.unwrap(elem)
				return
			}

			// 保持する class
			const className = selector.split('.').slice(1).join('')

			// 保持する属性
			const retainAttrs = (selector.match(/\[\w+\]/g) || []).map(attr => attr.replace(/[[\]]/g, '').toLowerCase())

			// span.bs-ed-color の色の情報のみを保持
			const color = (selector === 'span.bs-ed-color') ? elem.style.color : null

			// すべての属性を削除
			for (let attrs = elem.attributes, i = attrs.length - 1; i >= 0; i--) {
				const attrName = attrs[i].name
				if (!retainAttrs.includes(attrName.toLowerCase())) {
					elem.removeAttribute(attrName)
				}
			}

			// class を書き込む
			if (className) {
				elem.className = className
			} else {
				delete elem.className
			}

			// 色の情報を書き込む
			if (color) {
				elem.style.color = color
			}
		}
	}

})

export default Richtext
