import util from './util'

var views = {}
var viewClasses = {}
var viewId = 0

function View (/* parentView, obj */) {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {}
	me.dom = null

	me.initView.apply(me, arguments)
}

util.addProps(View.prototype, {

	initView (parentView, obj) {
		var me = this

		me.parentView = parentView

		if (!obj || !obj.T || obj.T !== me.T) {
			return
		}

		Object.keys(obj)
			.filter(key => key !== 'T')
			.forEach(key => {
				me.data[key] = me.wrapObject(obj[key])
			})
	},

	/// 永続化に使用するデータを取得する
	/// @return Object
	getData () {
		const gd = (val) => {
			if (Array.isArray(val)) {
				return val.map(gd)
			} else if (typeof val === 'object') {
				if (typeof val.getData === 'function') {
					return val.getData()
				}
				const obj = {}
				Object.keys(val)
					.filter(key => Object.prototype.hasOwnProperty.call(val, key) && val[key] != null)
					.forEach(key => {
						obj[key] = gd(val[key])
					})
				return obj
			} else {
				return val
			}
		}
		const retData = gd(this.data)
		retData.T = this.T
		return retData
	},

	/// View に含まれるテキストを取得する
	/// @return String
	getTexts () {
		const texts = [] // string[]
		const gt = (val) => {
			if (View.isView(val)) {
				if (typeof val.getTexts === 'function') {
					texts.push(val.getTexts())
				}
				return
			}
			if (Array.isArray(val)) {
				val.forEach(gt)
			} else if (typeof val === 'object') {
				Object.keys(val)
					.filter(key => Object.prototype.hasOwnProperty.call(val, key) && val[key] != null)
					.forEach(key => gt(val[key]))
			}
		}
		gt(this.data)
		return texts.filter(t => t.trim() !== '').join('\n\n')
	},

	/// View に含まれるアップロード一覧を取得する
	/// @return [String] - UID の配列
	getUploads () {
		const uploads = [] // string[]
		const gu = (val) => {
			if (View.isView(val)) {
				if (typeof val.getUploads === 'function') {
					uploads.push(...val.getUploads())
				}
				return
			}
			if (Array.isArray(val)) {
				val.forEach(gu)
			} else if (typeof val === 'object') {
				Object.keys(val)
					.filter(key => Object.prototype.hasOwnProperty.call(val, key) && val[key] != null)
					.forEach(key => gu(val[key]))
			}
		}
		gu(this.data)
		return uploads
	},

	// DOM を取得する。なければ createDOM() を呼ぶ
	getDOM () {
		if (!this.dom) {
			this.dom = this.createDOM()
		}
		return this.dom
	},

	// 子要素からの変更通知
	notifyChange () {
		if (this.parentView && typeof this.parentView.notifyChange === 'function') {
			this.parentView.notifyChange()
		}
	},

	// DOM 部品からの変更通知
	onUpdate (key, value/*, event */) {
		if (Object.prototype.hasOwnProperty.call(this.data, key)) {
			this.data[key] = value
		}
	},

	// DOM からのドロップ通知
	onDrop (/* event */) {},

	/// tab によるフォーカス移動
	/// @param fromView: View
	/// @param reverse: boolean
	/// @return focusMoved: boolean - フォーカス移動処理を行った場合は true を返す。false の場合はブラウザに動作をまかせる
	onTab (/* fromView, reverse */) {
		return false
	},

	/// Focus and blur
	focus (/* reverse */) {},
	blur () {},

	// ビューを JSON からクラスインスタンスに復元する
	wrapObject (obj) {
		var i, arr
		if (Array.isArray(obj)) {
			for (arr = [], i = 0; i < obj.length; i++) {
				arr.push(this.wrapObject(obj[i]))
			}
			return arr
		} else if (typeof obj.T === 'string' && viewClasses[obj.T]) {
			return new viewClasses[obj.T](this, obj)
		} else {
			return obj
		}
	},

	/// ルート View を取得する
	/// @return View
	getRoot () {
		var view = this

		while (view.parentView) {
			view = view.parentView
		}

		return view
	},

	/// type であり最も近い View を取得する
	/// @param type: string
	/// @return View?
	closest (type) {
		var view = this

		do {
			if (view.T === type) {
				return view
			}
			view = view.parentView
		} while (view)

		return null
	}

})

// 再描画通知
View.updateAll = () => Object.keys(views).forEach(id => views[id] && views[id].onUpdate(null, null))

// ビューに ID を振り登録する
View.register = (view) => {
	var id = 'bs-ed-' + (++viewId)
	views[id] = view
	return id
}

View.unregister = (view) => {
	if (!view || !view.id) {
		return
	}
	if (views[view.id]) {
		if (typeof view.destroy === 'function') view.destroy()
		views[view.id] = null
	}
}

// 登録されたビューを ID で探す
View.get = (id) => views[id]

// 指定された型の View を作って返す
View.create = (type, parentView, obj) => {
	if (viewClasses[type]) {
		return new viewClasses[type](parentView, obj == null ? {} : obj)
	} else {
		return null
	}
}

/// ビューを JSON からクラスインスタンスに復元できるようにする
/// @param name: string
/// @param viewClass: function
/// @param proto: object?
View.registerClass = (name, viewClass, proto) => {
	util.extend(viewClass, View)

	if (proto != null) {
		util.addProps(viewClass.prototype, proto)
	}

	viewClass.prototype.T = name
	viewClasses[name] = viewClass
}

/// obj が View のインスタンスかどうか調べる
View.isView = (obj) => obj && obj.getData && obj.getUploads && obj.getDOM && obj.onUpdate

export default View
