/* global Image */
import View from '../View'
import DOM from '../dom/DOM'

const EE = DOM.create

function YouTube () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		url: ''
	}
	me.dom = null
	me.firstInput = null
	me.preview = null
	me.help = null
	me.tim = null // temporary image
	me.prevUrl = null
	me.initView.apply(me, arguments)
}

View.registerClass('YouTube', YouTube, {

	createDOM () {
		const { id, data } = this

		this.preview = EE('div.llp')
		this.help = EE('div.llh')
		this.firstInput = EE('input.bs-ed-input-tr.llbb', {
			value: data.url,
			placeholder: 'YouTube の動画 URL',
			'bs-ed-var': 'url'
		})

		const dom = EE('div.bs-ed-view.bs-ed-view-YouTube', { id }, [
			EE('div.lli', {}, [
				EE('span.bs-ed-icon.bs-ed-icon-video'),
				this.firstInput,
			]),
			this.preview,
			this.help
		])

		setTimeout(() => this.updatePreview())

		return dom
	},

	focus () {
		if (this.firstInput) {
			this.firstInput.focus()
		}
	},

	onUpdate (/* key, value, event */) {
		YouTube.__super__.onUpdate.apply(this, arguments)
		this.updatePreview()
	},

	updatePreview () {
		const url = (this.data.url != null) ? this.data.url.trim() : ''
		const { preview } = this

		if (!preview) return
		if (url === this.prevUrl) return
		this.prevUrl = url

		this.setError(false)
		preview.style.backgroundImage = 'none'
		if (url === '') {
			this.preview.classList.add('llph')
			this.dom.classList.remove('llv')
			return
		}

		// https://www.youtube.com/watch?v=...
		// https://youtu.be/...
		const match = url.match(/\b(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)$/)
		if (!match) {
			this.setError(true, 'YouTube の動画 URL を指定してください')
			return
		}

		// URL を正規化したものをデータとして格納
		this.data.url = 'https://www.youtube.com/watch?v=' + match[1]
		this.notifyChange()

		// サムネイルを表示
		const imageURL = 'https://i.ytimg.com/vi/' + match[1] + '/mqdefault.jpg'
		this.tim = new Image()
		const { tim } = this
		tim.onload = function () {
			this.tim = null
			preview.classList.remove('llph')
			preview.style.backgroundImage = 'url(' + imageURL + ')'
		}
		tim.onerror = function () {
			this.setError(true, '動画が存在しない可能性があります')
			this.tim = null
		}
		tim.src = imageURL
		this.dom.classList.add('llv')
	},

	setError (cond, message) {
		message = message || ''
		this.dom.classList[cond ? 'add' : 'remove']('has-error')
		this.help.textContent = message
	}

})

export default YouTube
