/* global location */
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import DOM from '../dom/DOM'
import Evt from '../dom/Event'

const EE = DOM.create

/// @param editor: Editor
/// @param cb: function セット完了時に呼ぶコールバック
function openEditor (editor, cb) {
	var range, view, data, dialog, firstInput

	range = editor.selection.getRange()

	view = editor.getViewFromSelection('Richtext')
	if (!view) {
		return
	}

	data = readDataFromSelection(editor)

	dialog = createDialog(data)
	dialog.show()
	registerEvents(dialog)

	firstInput = DOM.q("input:not([type='hidden'])", dialog.getDOM())
	if (firstInput) {
		firstInput.focus()
		firstInput.select()
	}

	dialog.on('hide', function (ret) {
		if (ret === 'OK') {
			updateDOM(editor, range, values(dialog.getDOM()), view)
		}
		unregisterEvents(dialog)
		cb()
	})
}

function values (E) {
	const values = {};
	[].forEach.call(E.querySelectorAll('[name]'), inputE => {
		if (typeof inputE.value === 'undefined') return
		if (/^(?:radio|checkbox)$/i.test(inputE.getAttribute('type'))) {
			if (inputE.checked) values[inputE.name] = true
		} else {
			values[inputE.name] = inputE.value
		}
	})
	return values
}

function getTarget (editor) {
	return DOM.closest('a[href]', editor.selection.getNode())
}

/// このプラグインで編集するノードかどうかを返す
/// @param node: Element?
/// @retrun boolean
function isTarget (node) {
	return node && node.nodeName === 'A' && node.href
}

function readDataFromSelection (editor) {
	var linkE, data

	// 初期データ
	data = {
		text: '',
		initialText: '',
		href: '',
		target: '_top'
	}

	linkE = getTarget(editor)
	if (!isTarget(linkE)) {
		data.text = editor.selection.getText().replace(/(?:\r\n?|\n)+/g, ' ')
		data.initialText = data.text
		return data
	}

	data.text = linkE.textContent.replace(/(?:\r\n?|\n)+/g, ' ')
	data.initialText = data.text

	data.href = linkE.getAttribute('href')

	if (linkE.getAttribute('target') === '_blank') {
		data.target = '_blank'
	}

	return data
}

function createDialog (data) {
	var dialog, dialogContent, newWindowCheckbox

	newWindowCheckbox = EE('input.link-editor-input-new-window', {
		type: 'checkbox',
		name: 'newWindow'
	})
	if (data.target === '_blank') {
		newWindowCheckbox.checked = true
	}

	dialogContent = EE('div', {}, [
		Dialog.createFormGroup('URL', [
			EE('input.bs-ed-input.link-editor-input-url', {
				type: 'text',
				name: 'href',
				value: data.href
			}),
			EE('label.bs-ed-checkbox', {}, [
				newWindowCheckbox,
				DOM.text(' 新しいウィンドウで開く')
			])
		]),
		Dialog.createFormGroup('リンクテキスト', [
			EE('input.bs-ed-input', {
				type: 'text',
				name: 'text',
				value: data.text
			}),
			EE('input', {
				type: 'hidden',
				name: 'initialText',
				value: data.initialText
			})
		])
	])

	dialog = new Dialog('リンク', dialogContent, DialogButton.OKCancel)

	return dialog
}

function registerEvents (dialog) {
	var dialogDOM

	dialogDOM = dialog.getDOM()
	Evt.on(dialogDOM, 'input', function (event) {
		var inputE, newWindowE
		inputE = DOM.closest('.link-editor-input-url', event.target)
		if (!inputE) {
			return
		}
		newWindowE = DOM.q('.link-editor-input-new-window', dialogDOM)
		if (!newWindowE) {
			return
		}
		newWindowE.checked = isExternalLink(inputE.value)
	})
}

/// @param href: string
function isExternalLink (href) {
	var m, host

	if (!href) {
		return false
	}

	if ((m = href.match(/^((?:https?:)?\/\/)([0-9A-Za-z.-]+(?::[0-9]+)?)/))) {
		// protocol = m[1];
		host = m[2]
		if (host === location.host) {
			return false
		}
		return true
	}
	return false
}

function unregisterEvents (dialog) {
	var dialogDOM
	dialogDOM = dialog.getDOM()
	Evt.off(dialogDOM)
}

function updateDOM (editor, range, data, view) {
	var selection, parentE, linkE, linkAttributes, newRange

	// Range を復元
	selection = editor.selection
	selection.setRange(range)

	linkE = getTarget(editor)
	if (!isTarget(linkE)) {
		linkE = null
	}

	// 画像が設定されていなければリンクを削除する
	if (data.href === '') {
		if (linkE) {
			parentE = linkE.parentNode
			while (linkE.childNodes.length > 0) {
				parentE.insertBefore(linkE.childNodes[0], linkE)
			}
			parentE.removeChild(linkE)
			editor.updateRichtext(view)
		}
		editor.focus()
		return
	}

	// リンクの属性を設定
	linkAttributes = {
		href: data.href,
		target: data.newWindow ? '_blank' : null
	}

	// A 要素がなければ作る
	if (!linkE) {
		if (selection.isCollapsed()) {
			linkE = EE('a', linkAttributes, [DOM.text(data.text !== '' ? data.text : data.href)])
			selection.setNode(linkE)
		} else if (data.text !== data.initialText) {
			linkE = EE('a', linkAttributes, [DOM.text(data.text)])
			selection.setNode(linkE)
		} else {
			newRange = selection.formatInline('a', linkAttributes)
		}
	} else {
		DOM.modify(linkE, linkAttributes)

		// テキストが変更されていればセット
		if (data.text !== data.initialText) {
			linkE.textContent = data.text
		}
	}

	// 新たな選択範囲をつくる
	if (!newRange && linkE) {
		newRange = selection.createRange()
		newRange.setStart(linkE, 0)
		newRange.setEnd(linkE, linkE.childNodes.length)
	}

	editor.updateRichtext(view)

	if (newRange) {
		selection.setRange(newRange)
	}
}

export default {
	open: openEditor
}
