/* global getComputedStyle */

function adjustHeight (el) {
	var style, height, prevHeight, lineHeight, c

	if (!window.getComputedStyle) {
		return
	}
	style = getComputedStyle(el, null)

	if (el.scrollHeight > el.offsetHeight) {
		el.style.height = (el.scrollHeight) + 'px'
	} else {
		c = 100
		while (c--) {
			height = parseInt(style.height, 10)
			lineHeight = parseInt(style.lineHeight, 10)

			if (height === prevHeight) {
				break
			}
			prevHeight = height

			el.style.height = height - lineHeight + 'px'

			if (el.scrollHeight > el.offsetHeight) {
				el.style.height = el.scrollHeight + 'px'
				break
			}
		}
	}
}

export default {
	adjustHeight
}
