/* global Node, Element */
import Entities from './Entities'
import DOMReady from './DOMReady'

// セクション要素名
const sectionElements = 'address|article|aside|blockquote|body|div|dd|dt|dl|figcaption|figure|footer|form|header|hgroup|li|main|nav|noscript|ol|output|section|ul'.split('|')

// 段落要素名
const paragraphElements = 'h1|h2|h3|h4|h5|h6|p|pre'.split('|')

// リスト要素名
const listContainerElements = 'dl|ol|ul'.split('|')

// リスト項目要素名
const listItemElements = 'dd|dt|li'.split('|')

// ブロック要素名
const blockElements = sectionElements.concat(paragraphElements)

const DOM = {

	sectionElements,
	paragraphElements,
	listContainerElements,
	listItemElements,
	blockElements,

	/// @param query: string
	/// @param E: Element
	/// @return Element?
	q (query, E) {
		if (!E) {
			E = document
		}
		if (!E.querySelector) {
			return null
		}
		return E.querySelector(query)
	},

	/// @param query: string
	/// @param E: Element
	/// @return NodeList?
	qs (query, E) {
		if (!E) {
			E = document
		}
		if (!E.querySelectorAll) {
			return null
		}
		return E.querySelectorAll(query)
	},

	/// @param node: any
	/// @return boolean
	isNode (node) {
		return node instanceof Node
	},

	/// @param node: any
	/// @return boolean
	isElement (node) {
		return DOM.isNode(node) && node.nodeType === 1
	},

	/// @param node: any
	/// @return boolean
	isTextNode (node) {
		return DOM.isNode(node) && node.nodeType === 3
	},

	/// @param node: any
	/// @return boolean
	isCommentNode (node) {
		return DOM.isNode(node) && node.nodeType === 8
	},

	/// 親要素の何番目に位置するか返す
	/// @param node: Node
	/// @return number
	index (node) {
		return node.parentNode ? [].findIndex.call(node.parentNode.childNodes, c => c === node) : -1
	},

	/// @param query: string | [string]
	/// @param E: Element
	/// @return boolean
	is (query, E) {
		var matches, i

		if (Array.isArray(query)) {
			query = query.join(', ')
		}

		matches = E.parentNode.querySelectorAll(query)
		for (i = matches.length - 1; i >= 0; i--) {
			if (matches[i] === E) {
				return true
			}
		}
		return false
	},

	/// リッチテキスト (contenteditable 属性使用) として空かどうかを返す
	/// @param E: Element
	/// @return boolean
	isEmptyRichtext (E) {
		return [].every.call(E.childNodes, child => {
			if (DOM.isElement(child) && child.nodeName !== 'BR') {
				return false
			}
			if (DOM.isTextNode(child) && !/^\u200B?$/.test(child.nodeValue)) {
				return false
			}
			return true
		})
	},

	/// NOTE: NodeList が入力された場合でも Node の配列を返す点に注意
	/// @param query: string
	/// @param Es: NodeList | [Node]
	/// @return [Node]
	filter (query, Es) {
		var filteredEs, E, i

		for (filteredEs = [], i = 0; i < Es.length; i++) {
			E = Es[i]
			if (E.parentNode && [].indexOf.call(E.parentNode.querySelectorAll(query), E) >= 0) {
				filteredEs.push(E)
			}
		}
		return filteredEs
	},

	/// node の祖先のうち、node に一番近い query にマッチする要素を返す
	/// @param query: string
	/// @param node: Node
	/// @return Element?
	closest (query, node) {
		if (Array.isArray(query)) {
			query = query.join(', ')
		}

		if (typeof query !== 'string') {
			return DOM.closestNode(query, node)
		}

		// テキストノードやコメントノードに対しても取得できるようにする
		if (DOM.isTextNode(node) || DOM.isCommentNode(node)) {
			node = node.parentNode
		}

		// ネイティブの closest メソッドを使う
		if (window.Element && Element.prototype.closest) {
			if (!node.closest) {
				return null
			}
			return node.closest(query)
		}

		// Polyfill for IE11
		var matches, i, el
		matches = (node.document || node.ownerDocument).querySelectorAll(query)
		el = node
		do {
			i = matches.length
			while (--i >= 0 && matches[i] !== el) {}
		} while (i < 0 && (el = el.parentNode))
		return el
	},

	/// node の祖先に targetNode があれば targetNode を返す
	/// @param targetNode: Node
	/// @param node: Node
	/// @return Node?
	closestNode (targetNode, node) {
		while (node) {
			if (node === targetNode) {
				return node
			}
			node = node.parentNode
		}

		return null
	},

	/// 木構造における「次」のノードを取得する。
	/// HTML においては開始タグの出現順に要素を取得できる
	/// @param node: Node
	/// @return nextNode: Node?
	traverse (node) {
		if (node.firstChild) {
			return node.firstChild
		}

		if (node.nextSibling) {
			return node.nextSibling
		}

		do {
			node = node.parentNode
		} while (node && !node.nextSibling)

		if (!node) {
			return null
		}
		return node.nextSibling
	},

	/// 木構造における「前」のノードを取得する。
	/// HTML においては開始タグの出現順の逆順に要素を取得できる
	/// @param node: Node
	/// @return nextNode: Node?
	traverseReverse (node) {
		if (node.previousSibling) {
			node = node.previousSibling
			while (node && node.lastChild) {
				node = node.lastChild
			}
			return node
		}

		if (node.parentNode) {
			return node.parentNode
		}

		return null
	},

	/// 要素生成用のセレクタを要素名とクラス、IDに分ける。create() で使用する
	/// @param selector: string
	/// @param attributes: [string : string]?
	/// @return [tagName: string, attributes: object]
	parseSelector (selector, attributes) {
		var tagName, newAttributes, i, pos, mark, c, attr

		newAttributes = {}
		if (attributes != null) {
			Object.keys(attributes).forEach(function (key) {
				newAttributes[key] = attributes[key]
			})
		}

		for (i = 0, pos = 0; i <= selector.length; i++) {
			// 範囲外の場合、charAt() は "" を返す
			c = selector.charAt(i)
			if (c === '#' || c === '.' || c === '') {
				if (i === pos) {
					throw new Error('Invalid selector: ' + selector)
				}
				attr = selector.substring(pos, i)
				switch (mark) {
				case '#':
					newAttributes.id = attr
					break
				case '.':
					newAttributes.class = newAttributes.class ? newAttributes.class + ' ' + attr : attr
					break
				default:
					tagName = attr.toLowerCase()
					break
				}
				mark = c
				pos = i + 1
			}
		}

		if (tagName == null) {
			throw new Error('Invalid selector: ' + selector)
		}

		DOM.autofillAttributes(tagName, newAttributes)
		return [tagName, newAttributes]
	},

	/// @param elementName: string
	/// @param attr: object
	autofillAttributes (elementName, attr) {
		switch (elementName) {
		case 'input': {
			if (typeof attr.type === 'undefined') {
				attr.type = 'text'
			}
			break
		}
		case 'button': {
			if (typeof attr.type === 'undefined') {
				attr.type = 'button'
			}
			break
		}
		}
	},

	/// 要素ノードを作成する
	/// @param selector: string
	/// @param attributes: [string : string]?
	/// @param children: [Node]?
	/// @return Element
	create (selector, attributes, children) {
		const [elementName, trueAttributes] = DOM.parseSelector(selector, attributes)
		const E = document.createElement(elementName)
		DOM.modify(E, trueAttributes)

		if (children != null) {
			children.forEach(function (child) {
				if (typeof child === 'string') {
					child = DOM.text(child)
				}
				if (child != null) {
					E.appendChild(child)
				}
			})
		}

		return E
	},

	/// テキストノードを作成する
	/// @param content: string
	/// @return TextNode
	text (content) {
		return document.createTextNode(content)
	},

	/// フラグメントノードを作成する
	/// @param children: [Node]?
	fragment (children) {
		const fragment = document.createDocumentFragment()

		if (children != null) {
			children.forEach(function (child) {
				if (typeof child === 'string') {
					child = DOM.text(child)
				}
				if (child != null) {
					fragment.appendChild(child)
				}
			})
		}

		return fragment
	},

	/// @param E: Element
	/// @param attributes: [string : string]?
	modify (E, attributes) {
		if (attributes != null) {
			Object.keys(attributes).forEach(function (attr) {
				if (attr === 'style') {
					DOM.setStyles(E, attributes[attr])
					return
				}
				if (attributes[attr] === null) {
					E.removeAttribute(attr)
				} else {
					E.setAttribute(attr, attributes[attr])
				}
			})
		}

		return E
	},

	/// @param node: Node
	/// @param parentNode: Node
	append (node, parentNode) {
		parentNode.appendChild(node)
	},

	/// @param node: Node
	/// @param referenceNode: Node
	insertBefore (node, referenceNode) {
		referenceNode.parentNode.insertBefore(node, referenceNode)
	},

	/// @param node: Node
	/// @param referenceNode: Node
	insertAfter (node, referenceNode) {
		var parent, nextSibling

		parent = referenceNode.parentNode
		nextSibling = referenceNode.nextSibling

		if (nextSibling) {
			parent.insertBefore(node, nextSibling)
		} else {
			parent.appendChild(node)
		}
	},

	/// @param oldNode: Node
	/// @param newNode: Node
	/// @return replacedNode: Node
	replace (oldNode, newNode) {
		if (!oldNode || !oldNode.parentNode) {
			return null
		}
		return oldNode.parentNode.replaceChild(newNode, oldNode)
	},

	/// targetE を wrappingE で外包する
	/// @param targetE: Element
	/// @param wrappingE: Element
	/// @return wrappingE: Element
	wrap (targetE, wrappingE) {
		targetE.parentNode.insertBefore(wrappingE, targetE)
		wrappingE.appendChild(targetE)
		return wrappingE
	},

	/// wrappingE に囲まれている要素を wrappingE の外に出し、wrappingE を削除する
	/// @param wrappingE: Element
	unwrap (wrappingE) {
		var parentE

		parentE = wrappingE.parentNode
		if (!parentE) {
			return
		}

		while (wrappingE.firstChild) {
			parentE.insertBefore(wrappingE.firstChild, wrappingE)
		}

		parentE.removeChild(wrappingE)
	},

	/// インライン要素またはテキストノードを offset で分割する。
	/// offset はテキストノードの場合は文字数、要素の場合は何番目の兄弟かを指定する
	/// @param node: Node
	/// @param offset: number?
	/// @return splittedOuterElement: Element
	splitInlines (node, offset) {
		var doc, text, newNode, parent

		doc = node.document || node.ownerDocument

		if (offset == null) {
			offset = 0
		}

		// テキストノードを分割する
		if (DOM.isTextNode(node)) {
			if (offset > 0) {
				text = node.nodeValue
				if (text.length > offset) {
					newNode = doc.createTextNode(text.substr(offset))
					DOM.insertAfter(newNode, node)
					node.nodeValue = text.substr(0, offset)
					node = newNode
				} else {
					node = DOM.traverse(node)
				}
			} else {
				node = node.parentNode
			}
		} else {
			if (node.childNodes.length <= offset) {
				throw new RangeError('Invalid offset (element)')
			}
			node = node.childNodes[offset]
		}

		// インライン要素を分割する
		while (!DOM.is(blockElements, node.parentNode)) {
			parent = node.parentNode
			newNode = parent.cloneNode(false)
			while (parent.firstChild !== node) {
				newNode.appendChild(parent.firstChild)
			}
			parent.parentNode.insertBefore(newNode, parent)
			node = parent
		}

		return node
	},

	/// @param node: Element
	/// @preturn removedNode: Element?
	remove (node) {
		if (!node.parentNode) {
			return null
		}
		node.parentNode.removeChild(node)
		return node
	},

	/// @param node: Element
	/// @param styles: [string : string]
	setStyles (node, styles) {
		Object.keys(styles).forEach(prop => {
			node.style[prop] = styles[prop]
		})
	},

	getOuterHTML (node) {
		var tmpE

		if (node.nodeType === 1 && 'outerHTML' in node) {
			return node.outerHTML
		}

		tmpE = DOM.create('div')
		tmpE.appendChild(node.clone(true))
		return tmpE.innerHTML
	},

	/// @param text: string
	/// @return string
	encode: Entities.encode,

	/// @param text: string
	/// @return string
	decode: Entities.decode,

	/// @param listener: function
	ready: DOMReady

}

export default DOM
