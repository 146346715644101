import View from '../View'
import DOM from '../dom/DOM'
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'

const EE = DOM.create

function LinkButton () {
	var me = this

	me.id = View.register(me)
	me.parentView = null
	me.data = {
		label: 'ボタン',
		url: '',
		newWindow: false
	}
	me.dom = null
	me.initView.apply(me, arguments)
}

View.registerClass('LinkButton', LinkButton, {

	createDOM () {
		const { id, data } = this

		this.btnE = EE('div.bs-ed-view-LinkButton-btn', { title: data.url }, [data.label])

		return EE('div.bs-ed-view.bs-ed-view-LinkButton', { id }, [
			this.btnE,
			EE('div.bs-ed-view-LinkButton-tool', {}, [
				EE('button.bs-ed-btn-tr.bs-ed-act', {
					title: 'ボタン設定',
					tabindex: '-1',
					'data-act': 'openSetting'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-cog', {
						'aria-label': 'ボタン設定'
					})
				])
			])
		])
	},

	openSetting () {
		const { data } = this
		const labelInput = EE('input.bs-ed-input', { type: 'text', value: data.label })
		const urlInput = EE('input.bs-ed-input', { type: 'text', value: data.url })
		const newWindowInput = EE('input', { type: 'checkbox' })
		newWindowInput.checked = !!data.newWindow

		const dialogDOM = EE('div', {}, [
			Dialog.createFormGroup('ラベル', [
				labelInput
			]),
			Dialog.createFormGroup('リンク先', [
				urlInput,
				EE('label.bs-ed-checkbox', {}, [
					newWindowInput,
					' 新しいウィンドウで開く'
				])
			])
		])
		const dialog = new Dialog('ボタン', dialogDOM, DialogButton.OKCancel)

		dialog.on('hide', ret => {
			if (ret === 'OK') {
				data.label = labelInput.value
				data.url = urlInput.value
				data.newWindow = newWindowInput.checked

				this.btnE.textContent = data.label
				this.btnE.title = data.url

				this.parentView.notifyChange()
			}
		})

		dialog.show()
		setTimeout(() => {
			labelInput.focus()
			labelInput.select()
		})
	}

})

export default LinkButton
