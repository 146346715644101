import Sortable from 'sortablejs'
import Dialog from './ui/Dialog'
import DialogButton from './ui/DialogButton'
import View from './View'
import Richtext from './parts/Richtext'
import Formatter from './Formatter'
import Toolbar from './Toolbar'
import ToolbarButton from './ToolbarButton'
import Selection from './dom/Selection'
import DOM from './dom/DOM'
import Evt from './dom/Event'

import { defaultOptions, blockDesc, toolbarDesc, validSelectors } from './defs'

const EE = DOM.create
const isMobileSafari = /\biP(?:hone|[ao]d)\b/.test(navigator.userAgent)

const EditorEnv = (() => {
	const env = {
		ready: false,
		mobile: false,
		win: window,
		doc: document,
		root: null,
		body: null,
		instances: [],
		init: () => {
			if (env.ready) return

			env.root = document.documentElement
			env.body = document.body

			env.ready = true
		}
	}
	return env
})()

function Editor (parent, obj, options) {
	var me = this

	EditorEnv.init()

	// @var string
	me.id = View.register(me)

	// @var View
	me.parentView = null

	// @var object
	me.data = {
		parts: []
	}

	// @var object
	me.options = Object.assign({}, defaultOptions, options || {})

	// @var Element
	me.dom = null

	// @var Selection
	me.selection = new Selection(EditorEnv.win, EditorEnv.body)

	// @var Element
	me.contentE = null

	// @var boolean
	me.inserterOpened = false

	// @var Element
	me.inserterE = me.createInserterE()

	// @var int?
	me.inserterPosition = null

	// @var boolean
	me.inserterAlt = false

	// @var Element?
	me.focusingEditItemE = null

	// @var Element?
	me.hoveringEditItemE = null

	// @var Formatter
	me.formatter = new Formatter()

	// @var Toolbar
	me.toolbar = new Toolbar(me)
	me.initToolbar()

	// @var View
	me.activeView = null

	// @var int
	me.mobileScrollPosition = 0

	me.mobile = false

	me.initView.apply(me, arguments)
	me.cleanInput()

	if (me.data.parts.length === 0) {
		me.data.parts.push(new Richtext(me))
	}
}

View.registerClass('Editor', Editor, {

	/// 入力データから不正なデータを取り除く
	cleanInput () {
		var me = this; var parts; var aParts; var i

		parts = me.data.parts
		aParts = []
		for (i = 0; i < parts.length; i++) {
			if (View.isView(parts[i])) {
				aParts.push(parts[i])
			}
		}
		me.data.parts = aParts
	},

	getData () {
		var me = this; var retData

		retData = Editor.__super__.getData.apply(me)
		retData.excerpt = me.getTexts()
		retData.uploads = me.getUploads()
		return retData
	},

	createDOM () {
		var me = this; var editorE; var contentE; var mobileEditE

		contentE = EE('div.bs-ed-content')

		editorE = EE('div.bs-ed-view.bs-ed-view-Editor', {
			id: me.id
		}, [
			EE('div.bs-ed-toolbar-wrap', {}, [
				me.toolbar.getDOM(),
				EE('div.bs-ed-mobile-tool', {}, [
					EE('button.bs-ed-mobile-done.bs-ed-act', {
						type: 'button',
						'data-act': 'setMobileEditEnable:false'
					}, ['完了'])
				])
			]),
			EE('div.bs-ed-content-scroll', {}, [
				EE('div.bs-ed-content-wrap', {}, [contentE])
			]),
			(mobileEditE = EE('div.bs-ed-mobile-edit'))
		])

		me.contentE = contentE
		me.data.parts.forEach(me.appendView, me)

		Evt.on(editorE, 'click', me);

		['mousedown', 'touchstart', 'mouseover', 'mouseout', 'paste'].forEach((type) => {
			Evt.on(EditorEnv.doc, type, me)
		});

		['focusin', 'focusout'].forEach((type) => {
			Evt.on(contentE, type, me)
		})

		Evt.on(mobileEditE, 'click', () => { me.setMobileEditEnable(true) })

		this.sortable = new Sortable(contentE, {
			handle: '.bs-ed-editor-sort-handle',
			animation: 250,
			onUpdate () {
				me.sortHandler()
			}
		})

		return editorE
	},

	handleEvent (event) {
		var me = this; var fn = 'on' + event.type
		if (typeof me[fn] === 'function') {
			me[fn](event)
		}
	},

	onmousedown (event) {
		const me = this
		const eventTarget = event.target

		if (DOM.closest('.' + ToolbarButton.ButtonClass, eventTarget)) {
			return
		}

		const editItemE = DOM.closest('.bs-ed-edititem', eventTarget)
		if (editItemE !== me.focusingEditItemE) {
			if (me.focusingEditItemE) {
				me.focusingEditItemE.classList.remove('focus')
			}
			if (editItemE) {
				editItemE.classList.add('focus')
			}

			me.focusingEditItemE = editItemE
		}

		const viewE = DOM.closest('.bs-ed-view', eventTarget)
		const view = viewE ? View.get(viewE.id) : null
		if (view !== me.activeView) {
			// 表のボタンをちゃんと押したい
			if (!me.activeView || view !== me.activeView.parentView) {
				// フォーカスエリアが Richtext から外れた可能性があるので、一旦テキスト編集ツールバーを無効にする
				// Richtext にフォーカスが合った場合、後続の view.focus() でツールバーの状態を設定する
				me.toolbar.setEnabled(false)

				if (me.activeView) {
					me.activeView.blur()
				}
				if (view) {
					view.focus()
				}
				me.activeView = view
			}
		}

		if (me.inserterOpened && !DOM.closest('.bs-ed-inserter', eventTarget)) {
			me.hideInserter()
		}
	},

	ontouchstart (event) {
		var me = this

		if (!event.touches || event.touches.length !== 1) {
			return
		}

		if (isMobileSafari) {
			if (me.activeView && typeof me.activeView.maySelectionChange === 'function' && me.activeView.mobileTouchModified) {
				me.activeView.mobileTouchModified = false
				me.activeView.maySelectionChange()
			}
		}

		me.onmousedown(event)
	},

	onmouseover (event) {
		var me = this; var editItemE

		if (me.inserterOpened) {
			return // 挿入ボタンが開いているときは hover を無視する
		}

		editItemE = DOM.closest('.bs-ed-edititem', event.target)

		if (editItemE !== me.hoveringEditItemE) {
			if (me.hoveringEditItemE) {
				me.hoveringEditItemE.classList.remove('hover')
			}
			if (editItemE) {
				editItemE.classList.add('hover')
			}

			me.hoveringEditItemE = editItemE
		}
	},

	onmouseout (event) {
	},

	onclick (event) {
		var me = this

		me.buttonAction(event)
	},

	/// アクティブな View にフォーカスする
	focus () {
		const { activeView } = this

		if (activeView && activeView !== this) {
			activeView.focus()
		}
	},

	/// フォーカスを外す
	blur () {
		const { activeView } = this
		if (activeView) {
			this.activeView = null
			activeView.blur()
		}
	},

	/// @param view: View
	switchFocus (view) {
		var me = this; var editItemE

		editItemE = DOM.closest('.bs-ed-edititem', view.getDOM())
		if (editItemE !== me.focusingEditItemE) {
			if (me.focusingEditItemE) {
				me.focusingEditItemE.classList.remove('focus')
			}
			if (editItemE) {
				editItemE.classList.add('focus')
			}

			me.focusingEditItemE = editItemE
		}
	},

	buttonAction (event) {
		var me = this; var btn; var viewE; var view; var act; var args

		btn = DOM.closest('.bs-ed-act', event.target)
		if (!btn || (act = btn.getAttribute('data-act')) == null) {
			return false
		}
		args = act.split(':')
		act = args.shift()

		viewE = btn
		while (true) {
			viewE = DOM.closest('.bs-ed-view', viewE.parentNode)
			if (!viewE) {
				break
			}
			view = View.get(viewE.id)
			if (view && typeof view[act] === 'function') {
				view[act].apply(view, args)
				return true
			}
		}

		if (typeof me[act] === 'function') {
			me[act].apply(me, args)
			return true
		}
		return false
	},

	/// ブロックの種類を指定して追加する
	/// @param type: string
	appendPartByType (type) {
		var view = View.create(type, this)
		if (view) {
			this.appendPart(view)
		}
	},

	/// ブロックを追加する
	/// @param view: View
	appendPart (view) {
		var me = this

		// データを更新
		me.data.parts.push(view)

		// DOM を更新
		me.appendView(view)

		// 変更を通知
		me.notifyChange()

		// 入力欄にフォーカス
		view.focus()
		me.switchFocus(view)
	},

	/// @param view: View
	appendView (view) {
		this.contentE.appendChild(this.createPartDOM(view))
	},

	/// 部品の種類を指定して inserterPosition の位置に追加する
	/// @param type: string
	insertPartByType (type) {
		var me = this; var view; var position

		position = me.inserterPosition
		me.hideInserter()

		view = View.create(type, me)
		if (!view) {
			return
		}
		me.insertPart(view, position)
	},

	/// 部品を追加する
	/// @param view: View
	/// @param position: int
	insertPart (view, position) {
		var me = this; var partDOM

		if (position === null) {
			me.appendPart(view)
			return
		}

		// データを更新
		me.data.parts.splice(position, 0, view)

		// DOM を更新
		partDOM = me.createPartDOM(view)
		me.contentE.insertBefore(partDOM, me.contentE.childNodes[position])

		// 変更を通知
		me.notifyChange()

		// 入力欄にフォーカス
		view.focus()
		me.switchFocus(view)
	},

	/// @param view: View
	/// @return Element
	createPartDOM (view) {
		var partE = EE('div.bs-ed-edititem', {}, [
			// Tools (sort, remove)
			EE('div.bs-ed-edititem-tool', {}, [
				EE('button.bs-ed-toolbutton.bs-ed-danger.bs-ed-act', {
					type: 'button',
					tabindex: '-1',
					'data-act': 'removePartConfirm:' + view.id
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-remove', {
						title: 'このパーツを削除する'
					})
				]),
				EE('span.bs-ed-toolbutton.bs-ed-editor-sort-handle', {}, [
					EE('span.bs-ed-icon.bs-ed-icon-toggle-v', {
						title: 'ドラッグで並べ替え'
					})
				])
			]),
			// Inserter
			EE('div.bs-ed-inserter-cnt.bs-ed-inserter-before', {}, [
				EE('button.bs-ed-inserter-open-btn.bs-ed-act', {
					type: 'button',
					tabindex: '-1',
					'data-act': 'openInserter:' + view.id
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-plus')
				])
			]),
			// View
			view.getDOM(),
			// Inserter
			EE('div.bs-ed-inserter-cnt.bs-ed-inserter-after', {}, [
				EE('button.bs-ed-inserter-open-btn.bs-ed-act', {
					type: 'button',
					tabindex: '-1',
					'data-act': 'openInserter:' + view.id + ':true'
				}, [
					EE('span.bs-ed-icon.bs-ed-icon-plus')
				])
			])
		])

		if (typeof view.DOMReady === 'function') {
			view.DOMReady()
		}

		return partE
	},

	removePartConfirm (id) {
		var me = this; var view; var dialog
		view = View.get(id)
		if (view) {
			dialog = new Dialog('パーツの削除', 'このパーツを削除してもよろしいですか？', DialogButton.YesNo)
			dialog.on('hide', retval => {
				if (retval === 'yes') {
					me.removePart(view)
				}
				dialog = null
			})
			dialog.show()
		}
	},

	removePart (view) {
		const me = this
		const { parts } = me.data

		// 内部データから要素を取り除く
		const index = parts.findIndex(part => part === view)
		parts.splice(index, 1)

		// DOM を取り除く
		const viewE = DOM.closest('.bs-ed-edititem', DOM.q('#' + view.id))
		if (viewE) {
			DOM.remove(viewE)
		}

		me.parentView.notifyChange()

		// View の登録解除
		View.unregister(view)

		// パーツがなくなった場合は Richtext をひとつ作る
		if (parts.length === 0) {
			me.appendPart(new Richtext(me))
		}

		// 前後の要素にフォーカス
		const activeIndex = (index < parts.length) ? index : index - 1
		if (parts[activeIndex] && typeof parts[activeIndex].focus === 'function') {
			parts[activeIndex].focus()
		}
	},

	initToolbar () {
		var me = this; var toolbar = me.toolbar; var formatter = me.formatter

		toolbar.setEnabled(false)
		if (!me.options.toolbar || !Array.isArray(me.options.toolbar) || !me.options.toolbar[0]) {
			return
		}
		if (!Array.isArray(me.options.toolbar[0])) {
			throw new Error('Invalid option value. "toolbar" must be array of array.')
		}

		me.options.toolbar.forEach(function (toolNames) {
			var buttonGroup = []
			toolNames.forEach(function (toolName) {
				if (Object.prototype.hasOwnProperty.call(toolbarDesc, toolName)) {
					var desc = toolbarDesc[toolName]
					desc = (typeof desc === 'function') ? desc(me) : desc
					buttonGroup.push(mktb.apply(null, desc))
				}
			})
			if (buttonGroup.length >= 1) {
				toolbar.addButtonGroup(buttonGroup)
			}
		})

		/// @param command: string
		/// @param options: [string : any]
		/// @return ToolbarButton
		function mktb (command, options) {
			var args, fn

			if (!command) {
				return new ToolbarButton(options)
			}

			args = command.split(':')
			fn = args.shift()
			args.unshift(me)

			options.onclick = function () {
				var updated = false

				if (!toolbar.enabled) {
					return // ツールバーが無効
				}

				if (typeof formatter[fn] === 'function') {
					updated = formatter[fn].apply(formatter, args)
				}

				if (updated) {
					me.toolbar.selectionChange(me.selection)
					me.focus()
				}
			}
			return new ToolbarButton(options)
		}
	},

	/// @return Element
	createInserterE () {
		const me = this

		let afterSeparator = false
		const buttons = []
		if (!Array.isArray(me.options.blocks)) {
			throw new Error('Invalid option value. "block" must be array of string.')
		}
		me.options.blocks.forEach(function (blockName) {
			if (blockName === '|') {
				if (afterSeparator) throw new Error('Invalid blocks option. Multiple separator does not allowed.')
				afterSeparator = true
				me.createInserterTogglers(buttons)
			} else if (Object.prototype.hasOwnProperty.call(blockDesc, blockName)) {
				const args = blockDesc[blockName].concat([afterSeparator])
				buttons.push(me.createInserterButton.apply(me, args))
			} else {
				console.warn('The block "' + blockName + '" does not exist.')
			}
		})

		const inserterE = EE('div.bs-ed-inserter', {}, [
			EE('div.bs-ed-inserter-btns', {}, buttons)
		])
		return inserterE
	},

	/// @param title: string
	/// @param icon: string
	/// @param viewName: string
	/// @param afterSeparator: boolean
	/// @return Element
	createInserterButton (title, icon, viewName, afterSeparator) {
		const iconE = EE('span.bs-ed-inserter-btn-ic.bs-ed-icon')
		iconE.classList.add('bs-ed-icon-' + icon)

		const button = EE('button.bs-ed-inserter-btn.bs-ed-act', {
			type: 'button',
			title: title,
			'data-act': 'insertPartByType:' + viewName
		}, [
			iconE
		])
		if (afterSeparator) {
			button.classList.add('bs-ed-inserter-btn-as')
		}

		return button
	},

	createInserterTogglers (buttons) {
		buttons.push(EE('button.bs-ed-inserter-btn.bs-ed-act', {
			type: 'button',
			title: 'その他',
			'data-act': 'toggleInserter'
		}, [
			EE('span.bs-ed-inserter-btn-ic.bs-ed-icon.bs-ed-icon-dots')
		]))
		buttons.push(EE('button.bs-ed-inserter-btn.bs-ed-act.bs-ed-inserter-btn-as.bs-ed-inserter-btn-back', {
			type: 'button',
			title: '戻る',
			'data-act': 'toggleInserter'
		}, [
			EE('span.bs-ed-inserter-btn-ic.bs-ed-icon.bs-ed-icon-dots')
		]))
	},

	/// @param viewId: string
	/// @param after: string?
	openInserter (viewId, after) {
		const me = this

		const viewE = DOM.q('#' + viewId)
		if (!viewE || !viewE.parentNode) {
			return
		}

		const inserterContainer = DOM.q('.bs-ed-inserter-' + (after ? 'after' : 'before'), viewE.parentNode)
		if (!inserterContainer) {
			return
		}

		viewE.parentNode.classList.add('bs-ed-inserter-active')
		me.inserterE.classList.remove('bs-ed-inserter-btn-alt')
		inserterContainer.appendChild(me.inserterE)

		me.inserterAlt = false
		me.inserterOpened = true

		me.inserterPosition = null
		for (let i = 0; i < me.data.parts.length; i++) {
			if (me.data.parts[i].id === viewId) {
				me.inserterPosition = after ? i + 1 : i
				break
			}
		}
	},

	hideInserter () {
		var me = this; var viewE

		me.inserterOpened = false
		me.inserterPosition = null

		viewE = me.inserterE.parentNode
		viewE.parentNode.classList.remove('bs-ed-inserter-active')
		DOM.remove(me.inserterE)
	},

	toggleInserter () {
		var me = this
		me.inserterAlt = !me.inserterAlt
		me.inserterE.classList[me.inserterAlt ? 'add' : 'remove']('bs-ed-inserter-btn-alt')
	},

	sortHandler () {
		// DOM が並び変わっているので、内部データをそれに合わせる
		var me = this; var mParts = [];

		[].forEach.call(me.contentE.querySelectorAll('.bs-ed-edititem > .bs-ed-view'), viewE => {
			var view = View.get(viewE.id)
			mParts.push(view)
		})
		me.data.parts = mParts
		me.parentView.notifyChange()
	},

	/// @param view: View
	selectionChange (view) {
		var me = this

		me.activeView = view

		if (!me.toolbar.enabled) {
			me.toolbar.setEnabled(true)
		}

		me.toolbar.selectionChange(me.selection)
	},

	/// @param type: string?
	/// @return View?
	getViewFromSelection (type) {
		var me = this; var range; var viewE; var view

		range = me.selection.getRange()
		if (!range) {
			return null
		}

		viewE = DOM.closest('.bs-ed-view-Richtext', range.startContainer)
		if (!viewE) {
			return null
		}

		view = View.get(viewE.id)
		if (!view) {
			return null
		}

		if (type != null && view.T !== type) {
			return null
		}

		return view
	},

	/// Richtext を変更した際に呼ぶ。
	/// Richtext の内部データを更新し、Editor に変更通知を行う
	/// @param view: View? - 更新対象の View
	updateRichtext (view) {
		var me = this; var type = 'Richtext'

		if (!view) {
			view = me.getViewFromSelection(type)
			if (!view) {
				return
			}
		}

		if (view.T !== type) {
			console.warn('updateRichtext does not run: invalid view', view)
			return
		}

		view.updateInternalData()
		me.notifyChange()
	},

	/// モバイル：編集状態かどうかを切り替える
	setMobileEditEnable (aEnabled) {
		const { dom } = this
		const { root, body } = EditorEnv
		const enabled = (aEnabled === 'false') ? false : aEnabled

		if (enabled) {
			this.mobileScrollPosition = root.scrollTop || body.scrollTop
		}

		const em = enabled ? 'add' : 'remove'
		root.classList[em]('bs-ed-mobile-edit-enable')
		dom.classList[em]('bs-ed-mobile-edit-enable')

		const nem = enabled ? 'remove' : 'add'
		root.classList[nem]('bs-ed-mobile-edit-disable')
		dom.classList[nem]('bs-ed-mobile-edit-disable')

		if (!enabled) {
			root.scrollTop = body.scrollTop = this.mobileScrollPosition
			this.activeView = null
		}
	},

	/// tab によるフォーカス移動
	/// @param fromView: View
	/// @param reverse: boolean
	/// @return focusMoved: boolean
	onTab (fromView, reverse) {
		const { parts } = this.data
		const index = parts.findIndex(view => view === fromView)
		if (index < 0) {
			return false
		}
		const toIndex = index + (reverse ? -1 : 1)
		if (toIndex >= 0 && toIndex < parts.length) {
			const view = parts[toIndex]
			fromView.blur()
			view.focus(reverse)
			return true
		}
		return false
	}

})

export {
	Editor as default,
	validSelectors
}
