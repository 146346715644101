import DOM from './dom/DOM'

let id = 0
const instances = {}

function ToolbarButton (options) {
	var me = this

	Object.keys(options).forEach(function (key) {
		me[key] = options[key]
	})

	me.id = newId()
	me.E = null
	me.buttonE = null

	instances[me.id] = me
}

ToolbarButton.ItemClass = 'bs-ed-toolbar-item'
ToolbarButton.ButtonClass = 'bs-ed-toolbar-btn'

ToolbarButton.get = function (id) {
	return instances[id] || null
}

ToolbarButton.prototype = {

	/// @return Element
	getDOM () {
		var me = this
		if (!me.E) {
			me.E = me.createDOM()
		}
		return me.E
	},

	/// @return Element
	createDOM () {
		var me = this; var itemE; var buttonE

		buttonE = DOM.create('button.' + ToolbarButton.ButtonClass, {
			type: 'button',
			title: me.text
		}, [
			me.icon ? me.createIconDOM() : DOM.text(me.text)
		])
		me.buttonE = buttonE

		itemE = DOM.create('div.' + ToolbarButton.ItemClass, {
			id: me.id
		}, [
			buttonE
		])

		// ドロップダウンを追加
		if (me.dropdown) {
			itemE.appendChild(me.dropdown.getDOM())
		}

		return itemE
	},

	/// @return Element
	createIconDOM () {
		var me = this

		return DOM.create('span.bs-ed-icon.bs-ed-icon-' + me.icon)
	},

	/// stateSelector オプションによりボタンの状態を変更する
	/// @param node: Node
	updateSelectionNode (node) {
		var me = this; var active

		if (!me.stateSelector) {
			return
		}

		active = node && DOM.closest(me.stateSelector, node)

		me.buttonE.classList[active ? 'add' : 'remove']('active')
	}

}

function newId () {
	return 'bs-ed-btn-' + (++id)
}

export default ToolbarButton
