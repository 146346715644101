/* global zzl */
import DOM from '../dom/DOM'
import Dialog from '../ui/Dialog'
import DialogButton from '../ui/DialogButton'
import View from '../View'
import FileUploader from '../uploads/FileUploader'

const EE = DOM.create

const errorDialog = new Dialog('ファイルを追加できません', null, DialogButton.OK)

function File () {
	Object.assign(this, {
		id: View.register(this),
		parentView: null,
		data: {
			uid: null,
			type: null,
			name: null,
			size: null
		},
		dom: null
	})
	this.uploader = null
	this.initView.apply(this, arguments)

	if (this.parentView && this.parentView.parentView && this.parentView.parentView.T === 'Editor') {
		this.editor = this.parentView.parentView
	}
}

View.registerClass('File', File, {

	createDOM () {
		const { id, data } = this

		const E = EE('div.bs-ed-view.bs-ed-view-File.bs-ed-drop-target', { id }, [
			EE('div.llfi', {}, [
				EE('button.bs-ed-btn.bs-ed-btn-sm.bs-ed-btn-default.bs-ed-act', {
					'data-act': 'selectFile'
				}, [
					'ファイルを選択'
				])
			]),
			EE('input.llfn.bs-ed-input-lt', {
				value: (data.uid && data.name != null) ? data.name : '',
				placeholder: 'ファイル名（表示用）',
				'bs-ed-var': 'name'
			}, []),
			!zzl.uploadFilePrefix ? null : EE('button.llfp.bs-ed-btn.bs-ed-btn-sm.bs-ed-btn-default.bs-ed-act', {
				'data-act': 'previewFile'
			}, [
				'確認'
			]),
			EE('button.llfc.bs-ed-btn.bs-ed-btn-sm.bs-ed-btn-default.bs-ed-act', {
				'data-act': 'selectFile'
			}, [
				'変更'
			]),
			(this.parentView && this.parentView.T === 'FileList') ? (
				EE('div.tool', {}, [
					EE('button.bs-ed-toolbutton.bs-ed-danger.bs-ed-act', {
						tabIndex: '-1',
						'data-act': `removeItemConfirm:${this.id}`
					}, [
						EE('span.bs-ed-icon.bs-ed-icon-remove', { title: 'このパーツを削除する' })
					]),
					EE('span.bs-ed-toolbutton.bs-ed-editor-sort-handle', {}, [
						EE('span.bs-ed-icon.bs-ed-icon-toggle-v', { title: 'ドラッグで並べ替え' })
					])
				])
			) : null
		])

		if (data.uid) {
			E.classList.add('has-file')
		}

		return E
	},

	// ファイルをプレビュー
	previewFile () {
		if (!zzl.uploadFilePrefix || !this.data.uid) return
		const url = zzl.uploadFilePrefix + this.data.uid
		window.open(url)
	},

	// ファイル選択ダイアログを出す
	selectFile () {
		if (this.editor && typeof this.editor.options.fileSelectHandler === 'function') {
			this.editor.options.fileSelectHandler(this, values => {
				['uid', 'type', 'name', 'size'].forEach(key => {
					if (key in values) this.data[key] = values[key]
				})
				this.updateDOM()
				this.parentView.notifyChange()
			})
		} else {
			const input = document.createElement('input')
			input.type = 'file'
			input.accept = '*'
			input.onchange = event => {
				if (event && event.target && event.target.files && event.target.files[0]) {
					this.addUpload(event.target.files[0])
				}
			}
			input.click()
		}
	},

	updateDOM () {
		const { dom, data } = this
		dom.classList[data.uid ? 'add' : 'remove']('has-file')

		const name = (data.name != null) ? data.name : ''
		;[].forEach.call(dom.querySelectorAll('[bs-ed-var="name"]'), E => {
			if (/input|textarea/i.test(E.nodeName)) {
				E.value = name
			} else {
				E.textContent = name
			}
		})
	},

	getUploads () {
		return this.data.uid ? [this.data.uid] : []
	},

	// DOM からのドロップ通知
	onDrop (event) {
		const me = this
		const { dataTransfer } = event
		if (!dataTransfer) return

		event.preventDefault()
		if (dataTransfer.files && dataTransfer.files[0]) {
			me.addUpload(dataTransfer.files[0])
		}
	},

	addUpload (file) {
		var me = this

		me.dom.classList.remove('error')

		me.uploader = new FileUploader({
			file,
			complete: res => me.uploadComplete(res),
			error: err => me.uploadError(err)
		})
	},

	destroy () {
		this.uploader = null
	},

	uploadComplete (data) {
		var me = this; var key
		for (key in me.data) {
			if (Object.prototype.hasOwnProperty.call(me.data, key) && data[key] != null) {
				me.data[key] = data[key]
			}
		}
		me.updateDOM()
		me.parentView.notifyChange()
	},

	uploadError (message) {
		var me = this
		me.dom.classList.add('error')

		if (!errorDialog.isShown) {
			errorDialog.setSubtitle(localizeErrorMessage(message))
			errorDialog.show()
		}
	}

})

const errorMessageFail = 'アップロードに失敗しました'
const errorMessages = [
	[/\b(network error)\b/i, `${errorMessageFail}。ネットワークに接続されているかご確認ください`],
	[/\b(invalid response)\b/i, `${errorMessageFail}。サーバまたはネットワークで不具合が発生している可能性があります`],
	[/\b(entity too large)\b/i, 'ファイルサイズの上限を超えたためアップロードできませんでした'],
	[/\b(invalid post)\b/i, `${errorMessageFail}。ログイン状態をご確認ください`],
	[/\b(not save file)\b/i, `${errorMessageFail}。サーバで不具合が発生している可能性があります`]
]
function localizeErrorMessage (message) {
	const localized = errorMessages.find(([re, mes]) => re.test(message))
	return localized ? localized[1] : message
}

export default File
