/// Canvas 上の画像をギザギザしないように縮小する
/// http://jsdo.it/21f/nvIM

// @param src : Canvas | HTMLImageElement
function draw(src, dest, sx, sy, sw, sh, dw, dh) {
	sx = Math.round(sx);
	sy = Math.round(sy);
	sw = Math.round(sw);
	sh = Math.round(sh);
	dw = Math.round(dw);
	dh = Math.round(dh);
	
	var ctx = dest.getContext("2d")
	, width = sw
	, height = sh
	, tmpCanvas = document.createElement("canvas")
	, tmpCtx = tmpCanvas.getContext("2d")
	;
	if (width / dw <= 2) {
		ctx.fillStyle = "#ffffff";
		ctx.fillRect(0, 0, dw, dh); // 透過をなくす
		ctx.drawImage(src, sx, sy, sw, sh, 0, 0, dw, dh);
		tmpCanvas = tmpCtx = ctx = null;
		return;
	}

	tmpCanvas.width = width;
	tmpCanvas.height = height;
	tmpCtx.fillStyle = "#ffffff";
	tmpCtx.fillRect(0, 0, width, height); // 透過をなくす
	while (width / dw > 2) {
		var optw = getOptSize(width, dw), opth = getOptSize(height, dh);
		tmpCtx.drawImage(src, sx, sy, width, height, 0, 0, optw, opth);
		src = tmpCanvas;
		sx = sy = 0;
		width = optw;
		height = opth;
	}
	ctx.drawImage(src, 0, 0, width, height, 0, 0, dw, dh);
	tmpCanvas = tmpCtx = ctx = null;
}

function getOptSize(orig, target) {
	return target * Math.pow(2, Math.floor(Math.log(orig/target) / Math.LN2) - 1);
}

export default {
	draw: draw
};
