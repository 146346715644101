// 編集させるブロックの分だけ記述
import './parts/FloatPicture'
import './parts/PictureC'
import './parts/Table'
import './parts/Tabular'
import './parts/FileList'
import './parts/LinkButton'
import './parts/YouTube'
import './parts/Map'
import './parts/GMap'
import './parts/Twitter'
import './parts/Facebook'
import './parts/HTML'
import './parts/Anchor'

// デフォルトオプション
export const defaultOptions = {
	blocks: ['text', 'floatPicture', 'picture', 'table', 'files', 'linkButton', '|', 'youtube', 'gmap', 'twitter', 'facebook', 'html'],
	toolbar: [['h1', 'h2', 'h3'], ['ul', 'ol'], ['bold', 'color'], ['link']],
	colors: [
		'#333333', // 標準
		'#888888', // グレー
		'#bd2d2d', // 赤
		'#1c65ad', // 青
		'#bca736', // 黄
		'#26a14e', // 緑
		'#f27171', // 薄赤
		'#699ce7' // 薄青
	]
}

// 新規追加できるブロックの定義
export const blockDesc = {
	text: ['本文', 'text', 'Richtext'],
	floatPicture: ['本文と図', 'float', 'FloatPicture'],
	picture: ['画像', 'picture', 'PictureC'],
	table: ['表', 'table', 'Tabular'],
	files: ['ファイル', 'file', 'FileList'],
	linkButton: ['リンクボタン', 'button', 'LinkButton'],
	youtube: ['YouTube', 'video', 'YouTube'],
	map: ['地図', 'map', 'Map'],
	gmap: ['Googleマップ', 'map', 'GMap'],
	twitter: ['Twitter', 'twitter', 'Twitter'],
	facebook: ['Facebook', 'facebook', 'Facebook'],
	html: ['HTML', 'code', 'HTML'],
	anchor: ['アンカー', 'hash', 'Anchor']
}

// ツールバーの定義
export const toolbarDesc = {
	h1: ['toggleBlock:h2.heading', { icon: 'heading1', text: '大見出し', stateSelector: '.heading' }],
	h2: ['toggleBlock:h3.subhead', { icon: 'heading2', text: '中見出し', stateSelector: '.subhead' }],
	h3: ['toggleBlock:h4.subsubhead', { icon: 'heading3', text: '小見出し', stateSelector: '.subsubhead' }],
	ul: ['toggleList:ul', { icon: 'list-bullet', text: '箇条書き', stateSelector: 'ul' }],
	ol: ['toggleList:ol', { icon: 'list-ordered', text: '順序付きリスト', stateSelector: 'ol' }],
	bold: ['toggleInline:strong', { icon: 'bold', text: '強調', stateSelector: 'strong' }],
	big: ['toggleInline:big', { icon: 'big', text: '大きく', stateSelector: 'big' }],
	small: ['toggleInline:small', { icon: 'small', text: '小さく', stateSelector: 'small' }],
	del: ['toggleInline:del', { icon: 'strike', text: '取り消し線', stateSelector: 'del' }],
	ins: ['toggleInline:ins', { icon: 'underline', text: '下線', stateSelector: 'ins' }],
	color: editor => [null, { icon: 'color', text: '色', dropdown: editor.formatter.createColorMenu(editor.options.colors) }],
	link: ['editLink', { icon: 'link', text: 'リンク', stateSelector: 'a[href]' }]
}

// 貼り付け時に許可するセレクタ一覧
export const validSelectors = [
	'p',
	'h2.heading',
	'h3.subhead',
	'h4.subsubhead',
	'ul',
	'ol',
	'li',
	'strong',
	'a[href]',
	'span.bs-ed-color',
	'br'
]
