import DOM from './dom/DOM'
import Evt from './dom/Event'
import PopupManager from './PopupManager'
import ToolbarButton from './ToolbarButton'

const EE = DOM.create

function Toolbar (editor) {
	var me = this

	/// @var Editor
	me.editor = editor

	/// @var Element
	me.E = null

	/// @var [[ToolbarButton]]
	me.buttonGroups = []

	/// @var boolean
	me.enabled = true
}

Toolbar.Class = 'bs-ed-toolbar'
Toolbar.GroupClass = 'bs-ed-toolbar-grp'

Toolbar.prototype = {

	/// @param buttons: [ToolbarButton]
	addButtonGroup (buttons) {
		this.buttonGroups.push(buttons)
	},

	/// @param enabled: boolean
	setEnabled (enabled) {
		var me = this

		me.enabled = enabled

		if (me.E) {
			me.E.classList[enabled ? 'remove' : 'add']('disabled')
		}
	},

	/// @return Element
	getDOM () {
		if (!this.E) {
			this.E = this.createDOM()
		}
		return this.E
	},

	/// @return Element
	createDOM () {
		const { buttonGroups } = this
		const containerE = EE('div.' + Toolbar.Class, {}, buttonGroups.map(buttonGroup => {
			return EE('div.' + Toolbar.GroupClass, {}, buttonGroup.map(button => button.getDOM()))
		}))
		Evt.on(containerE, 'click', this)
		containerE.classList[this.enabled ? 'remove' : 'add']('disabled')
		return containerE
	},

	/// @private
	/// @param event: Event
	handleEvent (event) {
		var method = 'on' + event.type
		if (typeof this[method] === 'function') {
			this[method](event)
		}
	},

	/// @param event: Event
	onclick (event) {
		var me = this; var buttonE; var itemE; var button; var dropdown

		buttonE = DOM.closest('.' + ToolbarButton.ButtonClass, event.target)
		if (!buttonE) {
			return
		}

		itemE = DOM.closest('.' + ToolbarButton.ItemClass, buttonE)
		if (!itemE) {
			return
		}

		button = ToolbarButton.get(itemE.id)
		if (!button) {
			return
		}

		// ハンドラを実行
		if (button.onclick) {
			button.onclick(me.editor, buttonE)
		}

		// ドロップダウンがあれば開く
		if (button.dropdown && !DOM.closest('.bs-ed-toolbar-dropdown', buttonE)) {
			dropdown = button.dropdown
			if (PopupManager.isActive(dropdown)) {
				PopupManager.close()
			} else {
				PopupManager.open(dropdown)
				button.E.classList.add(PopupManager.OpenerClass)
			}
		}
	},

	/// @param selection: Selection
	selectionChange (selection) {
		const node = selection.getNode()
		this.buttonGroups.forEach(buttonGroup => {
			buttonGroup.forEach(button => button.updateSelectionNode(node))
		})
	}

}

export default Toolbar
