import DOM from '../dom/DOM'
import DialogButton from './DialogButton'
import Backdrop from './Backdrop'

// @var int ダイアログ id シーケンス
let maxId = 0

// @var Backdrop
const commonBackdrop = new Backdrop()

// @var [Dialog] 全てのインスタンス
const instances = []

// @var [Dialog] 表示中のダイアログ
const visibleDialogs = []

function globalInit () {
	instances.forEach(inst => inst.updateDOM())

	// キーボードによる操作
	window.addEventListener('keydown', keydownHandler)
}

function addToVisible (dialog) {
	removeFromVisible(dialog)
	visibleDialogs.push(dialog)
}

function removeFromVisible (dialog) {
	const index = visibleDialogs.findIndex(d => d === dialog)
	if (index >= 0) {
		visibleDialogs.splice(index, 1)
	}
}

function keydownHandler (event) {
	if (visibleDialogs.length < 1) return
	const dialog = visibleDialogs[visibleDialogs.length - 1]
	const mod = (event.metaKey ? 8 : 0) | (event.altKey ? 4 : 0) | (event.ctrlKey ? 2 : 0) | (event.shiftKey ? 1 : 0)
	switch (event.key) {
		case 'Escape':
			if (mod === 0) dialog.hide()
			break
	}
}

/// ダイアログ
/// ----------------------------------------------------------------

class Dialog {
	/// @param title: string?
	/// @param subtitle: string | Node | null
	/// @param buttons: [DialogButton]
	constructor (title, subtitle, buttons) {
		this.id = ++maxId
		this.visible = false
		this.title = null
		this.subtitle = null
		this.buttons = []

		this.E = null
		this.ready = false

		this.wrapE = null
		this.wrapReady = false

		this.setTitle(title)
		this.setSubtitle(subtitle)
		this.setButtons(buttons)

		this.listeners = []
	}

	show () {
		this.setVisible(true)
	}

	hide () {
		this.setVisible(false)
	}

	/// @param visible: boolean
	setVisible (visible) {
		if (this.visible === visible) {
			return
		}
		this.visible = visible

		this.updateDOM()
		if (!this.wrapReady || !this.ready) {
			return
		}

		const ecl = this.E.classList
		if (visible) {
			this.wrapE.appendChild(this.E)
			ecl.add('active')
			this.E.addEventListener('click', this)

			if (visibleDialogs.length === 0) {
				commonBackdrop.show()
			}
			addToVisible(this)
		} else {
			ecl.add('out')
			setTimeout(() => {
				ecl.remove('out')
				ecl.remove('active')
				this.wrapE.removeChild(this.E)
			}, 220)
			this.E.removeEventListener('click', this)

			removeFromVisible(this)
			if (visibleDialogs.length === 0) {
				commonBackdrop.hide()
			}
		}
	}

	/// @param type: string
	/// @param listener: function | object
	on (type, listener) {
		this.listeners.push({ type, listener })
	}

	/// @param type: string
	/// @param listener: function | object
	off (type, listener) {
		const updated = this.listeners.filter(lis => lis.type !== type || (listener ? (lis.listener !== listener) : false))
		this.listeners.splice(0, this.listeners.length, ...updated)
	}

	/// @param type: string
	/// @param ...args: [any]
	fire (type, ...args) {
		this.listeners.filter(lis => lis.type === type)
			.forEach(lis => lis.listener.apply(null, args))
	}

	/// @param title: string?
	setTitle (title) {
		if (title !== null && typeof title !== 'string') {
			throw new TypeError('Title must be string or null')
		}
		if (this.title !== title) {
			this.title = title
			this.ready = false
		}
	}

	/// @param subtitle: string | Node | null
	setSubtitle (subtitle) {
		if (subtitle !== null && typeof subtitle !== 'string' && !DOM.isNode(subtitle)) {
			throw new TypeError('Subtitle must be string, Node or null')
		}
		if (this.subtitle !== subtitle) {
			this.subtitle = subtitle
			this.ready = false
		}
	}

	/// @param buttons: [DialogButton]
	setButtons (buttons) {
		this.buttons = (Array.isArray(buttons) ? buttons : [buttons]).map(DialogButton.wrap)
		this.ready = false
	}

	getDOM () {
		return this.E
	}

	updateDOM () {
		if (!this.wrapReady) {
			const wrapE = document.body
			if (wrapE) {
				this.wrapE = wrapE
				this.wrapReady = true
			}
		}

		if (this.wrapReady && !this.ready) {
			const contentE = DOM.create('div.bs-ed-dialog-content', {}, [
				this.createDialogElement(this.title, 'title'),
				this.createDialogElement(this.subtitle, 'subtitle'),
				this.buttons.length > 0
					? DOM.create('div.bs-ed-dialog-actions', {}, this.buttons.map(this.createButtonElement))
					: null
			])
			const dialogE = DOM.create('div.bs-ed-dialog-dialog', {}, [contentE])
			this.E = DOM.create('div.bs-ed-dialog', {}, [dialogE])
			this.ready = true

			if (this.visible) {
				this.E.classList.add('active')
			}
		}
	}

	createDialogElement (content, classSuffix) {
		if (content === null) {
			return null // null to nothing
		}
		return DOM.create('div.bs-ed-dialog-' + classSuffix, {}, this.contentToElement(content))
	}

	contentToElement (content) {
		if (typeof content === 'string') {
			return content.split('\n').reduce((nodes, line) => {
				if (nodes.length > 0) {
					nodes.push(DOM.create('br'))
				}
				nodes.push(DOM.text(line))
				return nodes
			}, [])
		} else if (DOM.isNode(content)) {
			return [content]
		} else {
			return null
		}
	}

	createButtonElement (button) {
		const E = DOM.create('button.bs-ed-btn', { type: 'button', value: button.value }, [button.label])
		if (button.style) {
			button.style.split(/\s+/)
				.map(s => 'bs-ed-btn-' + s)
				.forEach(s => E.classList.add(s))
		}
		return E
	}

	handleEvent (event) {
		if (event.type !== 'click') {
			return
		}

		const buttonE = DOM.closest('.bs-ed-btn', event.target)
		if (!buttonE || !DOM.closest('.bs-ed-dialog-actions', buttonE)) {
			return
		}
		const retval = buttonE.value
		this.hide()
		this.fire('hide', retval)
	}
}

/// @return dialog: Dialog?
Dialog.getActiveDialog = function () {
	if (visibleDialogs.length) {
		return visibleDialogs[visibleDialogs.length - 1]
	} else {
		return null
	}
}

Dialog.createFormGroup = function (label, controls) {
	return DOM.create('div.bs-ed-form-group', {}, [
		DOM.create('div.bs-ed-form-label', {}, [DOM.text(label)]),
		DOM.create('div.bs-ed-form-controls', {}, controls)
	])
}

DOM.ready(() => {
	globalInit()
})

export default Dialog
