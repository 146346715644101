function doNothing() {}

export default class FileUploader {

	constructor(opts) {
		Object.assign(this, {
			file: opts.file,
			onComplete: typeof opts.complete === "function" ? opts.complete : doNothing,
			onError: typeof opts.error === "function" ? opts.error : doNothing,
			// media (リビジョン管理つき) が使用可能なら file より優先する
			uploadTo: opts.uploadTo || zzl.uploadMediaTo || zzl.uploadFileTo || null,
		});
		this.readFile();
	}

	readFile() {
		const reader = new FileReader;
		reader.onload = event => this.upload(event.target.result);
		reader.readAsDataURL(this.file);
	}
	
	upload(dataURL) {
		const {uploadTo, file, onComplete, onError} = this;
		if (uploadTo == null) {
			return;
		}

		let prevResponse = null;

		fetch(uploadTo, {
			method: "POST",
			cache: "no-cache",
			credentials: "same-origin",
			body: this.postData({
				_VALIDPOST: zzl.env.post_token || zzl.env.session_id,
				content: dataURL,
				name: file.name,
				size: file.size,
			}),
		})
		.catch(err => { throw new Error("Network error") })
		.then(res => (prevResponse = res, res.json()))
		.catch(err => {
			if (prevResponse && prevResponse.status === 413) throw new Error("Request Entity Too Large");
			throw new Error("Invalid response");
		})
		.then(json => {
			if (prevResponse && prevResponse.ok) onComplete(json);
			else onError(json.message);
		})
		.catch(err => onError(err.message));
	}

	postData(data) {
		const formData = new FormData();
		Object.keys(data).forEach(key => formData.append(key, data[key]));
		return formData;
	}
}
