const table = {
	// 8: "Backspace",
	9: 'Tab'
	// 13: "Enter", // NumpadEnter
	// 16: "ShiftLeft", // ShiftRight
	// 17: "ControlLeft", // ControlRight
	// 18: "AltLeft", // AltRight
	// 19: "MetaLeft", // MetaRight ?
	// 20: "CapsLock",
	// 27: "Escape",
	// 32: "Space",
	// 46: "Delete",
	// 91: "OSLeft", // Windows
	// 92: "OSRight", // Windows
	// 229: "IME",
	// 240: "CapsLock",
}

class KeyEventWrap {
	constructor (event) {
		this.e = event
	}

	get modifier () {
		const { e } = this
		return (e.shiftKey ? 1 : 0) +
			(e.ctrlKey ? 2 : 0) +
			(e.altKey ? 4 : 0) +
			(e.metaKey ? 8 : 0)
	}

	get key () {
		return this.e.key || String.fromCharCode(this.e.keyCode)
	}

	get code () {
		if (this.e.code) return this.e.code
		const key = String.fromCharCode(this.e.keyCode)
		if (/0-9A-Z/.test(key)) return 'Key' + key
		return table[this.e.keyCode] || null
	}
}

export default KeyEventWrap
