// ブラウザが Exif orientation に基づいて画像を自動回転する場合は true を返す
export default function () {
	var matched,
	vendor = navigator.vendor,
	appVersion = navigator.appVersion,
	userAgent = navigator.userAgent;

	// Google Chrome, Edge ≥ 81.0 は image-orientation 対応
	if (vendor === 'Google Inc.') {
		matched = appVersion.match(/\bChrome\/([0-9]+)/);
		return (matched && parseInt(matched[1], 10) >= 81);
	}

	// Safari ≥ 13.1 は image-orientation 対応
	if (vendor === 'Apple Computer, Inc.') {
		matched = appVersion.match(/\bVersion\/([0-9]+[.][0-9]+)/);
		return matched && parseFloat(matched[1]) < 13.1;
	}

	// Firefox ≥ 77 は image-orientation 対応
	if (/\bFirefox\b/.test(userAgent)) {
		matched = navigator.userAgent.match(/\bFirefox\/([0-9]+[.][0-9]+)\b/);
		return matched && parseFloat(matched[1]) >= 77.0;
	}

	return false;
};
